import RuleIcon from '@mui/icons-material/Rule';
import RuleConditionList from "./RuleConditionList";
import RuleConditionCreate from "./RuleConditionCreate";
import RuleConditionEdit from "./RuleConditionEdit";
import RuleConditionShow from "./RuleConditionShow";

const resource = {
  list: RuleConditionList,
  create: RuleConditionCreate,
  edit: RuleConditionEdit,
  show: RuleConditionShow,
  icon: RuleIcon,
};

export default resource;
