import {
  Show,
  SimpleShowLayout,
  ReferenceField,
  TextField,
} from "react-admin";
import RuleConditionValues from "./RuleConditionValues";

const RuleConditionShow = () => (
  <Show>
    <SimpleShowLayout>
      <ReferenceField source="rule_id" reference="rule" link={false}>
        <TextField source="name" />
      </ReferenceField>
      <ReferenceField source="class_id" reference="rule_condition_class">
        <TextField source="name" />
      </ReferenceField>
      <RuleConditionValues mode="show" />
    </SimpleShowLayout>
  </Show>
);

export default RuleConditionShow;
