import {
  Edit,
  SaveButton,
  SimpleForm,
  Toolbar,
  TextInput,
  DateInput,
  SelectInput,
  SimpleFormIterator,
  FormDataConsumer,
  Labeled,
  Button,
  ReferenceManyField,
  Datagrid,
  TextField,
  ReferenceInput,
  useRecordContext,
  useDataProvider,
  useRedirect,
  useNotify,
  Pagination,
} from "react-admin";
import { useState } from "react";
import { useFormContext } from "react-hook-form";
import { RichTextInput } from "ra-input-rich-text";
import {ReferenceManyInput} from "@react-admin/ra-relationships";
import PatientTitle from "./PatientTitle";
import InsuranceProviderInput from "./InsuranceProviderInput";
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import GatekeeperInput from "../providerpractitioner/GatekeeperInput";
import ProviderInput from "../provider/ProviderInput";
import {
  humanaRegex,
  mcareRegex,
  simplyRegex,
  careplusRegex,
  devotedRegex,
  cignaRegex,
  aetnaRegex,
  unitedRegex,
  bcbsRegex,
  medicaRegex,
  preferredCareRegex,
  avmedRegex,
} from "../constants";
import {VITE_DEFAULT_LISTSHOW_PER_PAGE} from "../config";


const eligibilityKey = () => {
  return "@@ra-many/patient/patient_eligibility/patient_id";
};

const identifierKey = () => {
  return "@@ra-many/patient/patient_identifier/patient_id";
};

const validatePatientIdentifierItem = (item) => {
  const required_fields = [
    ['id_type', 'ID type'],
    ['id_value', 'ID'],
  ];
  const itemErrors = {};
  for (const f of required_fields) {
    if (!item[f[0]]) {
      itemErrors[f[0]] = `${f[1]} is required`;
    }
  }
  if (item.id_type === 'humana' && typeof item.id_value === 'string' && !humanaRegex.test(item.id_value)) {
    itemErrors.id_value = 'Must be a valid Humana ID (H followed by 10 digits)';
  }
  if (item.id_type === 'medicare' && typeof item.id_value === 'string' && !mcareRegex.test(item.id_value)) {
    itemErrors.id_value = 'Must be a valid Medicare ID';
  }
  if (item.id_type === 'simply' && typeof item.id_value === 'string' && !simplyRegex.test(item.id_value)) {
    itemErrors.id_value = 'Must be a valid Simply Health ID';
  }
  if (item.id_type === 'careplus' && typeof item.id_value === 'string' && !careplusRegex.test(item.id_value)) {
    itemErrors.id_value = 'Must be a valid Careplus ID';
  }
  if (item.id_type === 'devoted' && typeof item.id_value === 'string' && !devotedRegex.test(item.id_value)) {
    itemErrors.id_value = 'Must be a valid Devoted ID';
  }
  if (item.id_type === 'cigna' && typeof item.id_value === 'string' && !cignaRegex.test(item.id_value)) {
    itemErrors.id_value = 'Must be a valid Cigna ID';
  }
  if (item.id_type === 'aetna' && typeof item.id_value === 'string' && !aetnaRegex.test(item.id_value)) {
    itemErrors.id_value = 'Must be a valid Aetna ID';
  }
  if (item.id_type === 'united' && typeof item.id_value === 'string' && !unitedRegex.test(item.id_value)) {
    itemErrors.id_value = 'Must be a valid United Health ID';
  }
  if (item.id_type === 'bcbs' && typeof item.id_value === 'string' && !bcbsRegex.test(item.id_value)) {
    itemErrors.id_value = 'Must be a valid Blue Cros Blue Shield ID';
  }
  if (item.id_type === 'medica' && typeof item.id_value === 'string' && !medicaRegex.test(item.id_value)) {
    itemErrors.id_value = 'Must be a valid Medica Shield ID';
  }
  if (item.id_type === 'preferredcare' && typeof item.id_value === 'string' && !preferredCareRegex.test(item.id_value)) {
    itemErrors.id_value = 'Must be a valid Preferred Care Partners ID';
  }
  if (item.id_type === 'avmed' && typeof item.id_value === 'string' && !avmedRegex.test(item.id_value)) {
    itemErrors.id_value = 'Must be a valid AVMed ID';
  }
  return itemErrors;
};

const validatePatientIdentifierUpdate = (items) => {
  const errors = [];
  if (items.length === 0) {
    errors.push('Required');
  }
  for (const i in items) {
    const item = items[i];
    if (item.is_created_manually !== null && item.is_created_manually !== undefined && !item.is_created_manually) {
      errors.push({});
    }
    else {
      errors.push(validatePatientIdentifierItem(item));
    }
  }
  return errors.find(e => Object.keys(e).length > 0) !== undefined ? errors : [];
};

const validatePatientEligibilityItem = (item) => {
  const required_fields = [
    ['date_from', 'Date from'],
    ['date_to', 'Date to'],
    ['insurance_provider_id', 'Insurance provider'],
    ['identifier_id', 'ID'],
    ['lob_id', 'Line of business'],
    ['gatekeeper_id', 'Gatekeeper'],
  ];
  const itemErrors = {};
  for (const f of required_fields) {
    if (!item[f[0]]) {
      itemErrors[f[0]] = `${f[1]} is required`;
    }
  }
  return itemErrors;
};

const validatePatientEligibilityUpdate = (items) => {
  const errors = [];
  if (items.length === 0) {
    return [];
  }
  for (const i in items) {
    const item = items[i];
    if (item.is_created_manually !== null && item.is_created_manually !== undefined && !item.is_created_manually) {
      errors.push({});
    }
    else {
      errors.push(validatePatientEligibilityItem(item));
    }
  }
  return errors.find(e => Object.keys(e).length > 0) !== undefined ? errors : [];
};

const validatePatientSupervisorUpdate = (values) => {
  const required_fields = [
    ['name', 'Name'],
    ['date_of_birth', 'Date of birth'],
  ];
  const errors = {};
  for (const f of required_fields) {
    if (!values[f[0]]) {
      errors[f[0]] = `${f[1]} is required`;
    }
  }
  const identifierErrors = validatePatientIdentifierUpdate(values[identifierKey()][0]["patient_identifier"]);
  if (identifierErrors.length > 0) {
    errors[identifierKey()] = [{"patient_identifier": identifierErrors}];
  }
  const eligibilityErrors = validatePatientEligibilityUpdate(values[eligibilityKey()][0]["patient_eligibility"]);
  if (eligibilityErrors.length > 0) {
    errors[eligibilityKey()] = [{"patient_eligibility": eligibilityErrors}];
  }
  return errors;
};

const PatientEditToolbar = () => {
  return (
    <Toolbar>
      <SaveButton/>
    </Toolbar>
  )
}

const PatientEditNames = () => (
  <Labeled label="Names">
    <ReferenceManyField
      reference="patient_name"
      target="patient_id"
      perPage={VITE_DEFAULT_LISTSHOW_PER_PAGE}
      pagination={<Pagination />}
    >
      <Datagrid bulkActionButtons={false}>
        <TextField source="first_name" />
        <TextField source="last_name" />
      </Datagrid>
    </ReferenceManyField>
  </Labeled>
);

const PatientEditIdentifiers = () => {
  const [running, setRunning] = useState(false);
  const { setValue, getValues } = useFormContext();

  const canEditIdentifier = (data) => {
    if (data && (data.is_created_manually === null || data.is_created_manually === undefined)) {
      data.is_created_manually = true;
    }
    return data?.is_created_manually;
  };

  const removeIdentifier = (data) => {
    setRunning(true);
    const identifierKey = "@@ra-many/patient/patient_identifier/patient_id";
    const eligibilities = getValues(identifierKey)[0]["patient_identifier"];
    eligibilities.splice(eligibilities.indexOf(data), 1);
    setValue(identifierKey, [{"patient_identifier" : eligibilities}], { shouldDirty: true });
    setRunning(false);
  };

  return (
    <ReferenceManyInput reference="patient_identifier" target="patient_id">
      <SimpleFormIterator inline disableClear disableRemove>
        <FormDataConsumer>
          {
            ({getSource, scopedFormData}) => {
              return scopedFormData === undefined ? null : (
                <>
                  <InsuranceProviderInput
                    label="ID Type"
                    source={getSource("id_type")}
                    medicare="true"
                    record={scopedFormData}
                    disabled={!canEditIdentifier(scopedFormData)}
                  />
                  <TextInput
                    label="ID"
                    source={getSource("id_value")}
                    record={scopedFormData}
                    disabled={!canEditIdentifier(scopedFormData)}
                  />
                  {
                    canEditIdentifier(scopedFormData) ? (
                      <Button
                        disabled={running}
                        onClick={() => removeIdentifier(scopedFormData)}
                        sx={{marginTop: '4px', padding: '5px'}}
                      >
                        <RemoveCircleOutlineIcon sx={{color: 'rgb(237, 108, 2)'}} />
                      </Button>
                    ) : null
                  }
                </>
              )
            }
          }
        </FormDataConsumer>
      </SimpleFormIterator>
    </ReferenceManyInput>
  );
};

const PatientEditEligibility = () => {
  const patient = useRecordContext();
  const [running, setRunning] = useState(false);
  const { setValue, getValues } = useFormContext();

  const canEditEligibility = (data) => {
    if (data && (data.is_created_manually === null || data.is_created_manually === undefined)) {
      data.is_created_manually = true;
    }
    return data?.is_created_manually;
  };

  const removeEligibility = (data) => {
    setRunning(true);
    const eligibilityKey = "@@ra-many/patient/patient_eligibility/patient_id";
    const eligibilities = getValues(eligibilityKey)[0]["patient_eligibility"];
    eligibilities.splice(eligibilities.indexOf(data), 1);
    setValue(eligibilityKey, [{"patient_eligibility" : eligibilities}], { shouldDirty: true });
    setRunning(false);
  };

  return (
    <ReferenceManyInput reference="patient_eligibility" target="patient_id">
      <SimpleFormIterator inline disableClear disableRemove>
        <FormDataConsumer>
          {
            ({getSource, scopedFormData}) => {
              return scopedFormData === undefined ? null : (
                <>
                  <DateInput
                    source={getSource("date_from")}
                    record={scopedFormData}
                    disabled={!canEditEligibility(scopedFormData)}
                  />
                  <DateInput
                    source={getSource("date_to")}
                    record={scopedFormData}
                    disabled={!canEditEligibility(scopedFormData)}
                  />
                  <InsuranceProviderInput
                    label="Insurance provider"
                    source={getSource("insurance_provider_id")}
                    record={scopedFormData}
                    disabled={!canEditEligibility(scopedFormData)}
                  />
                  <ReferenceInput
                    label="ID"
                    source={getSource("identifier_id")}
                    reference="patient_identifier"
                    filter={{patient_id: patient.id}}
                    record={scopedFormData}
                    disabled={!canEditEligibility(scopedFormData)}
                  >
                    <SelectInput
                      label="ID"
                      optionText="id_value"
                      disabled={!canEditEligibility(scopedFormData)}
                    />
                  </ReferenceInput>
                  <ReferenceInput
                    label="Line of business"
                    source={getSource("lob_id")}
                    reference="line_of_business"
                    record={scopedFormData}
                    disabled={!canEditEligibility(scopedFormData)}
                  >
                    <SelectInput
                      label="Line of business"
                      optionText="description"
                      disabled={!canEditEligibility(scopedFormData)}
                    />
                  </ReferenceInput>
                  <GatekeeperInput
                    label="Gatekeeper"
                    source={getSource("gatekeeper_id")}
                    record={scopedFormData}
                    disabled={!canEditEligibility(scopedFormData)}
                  />
                  <ProviderInput
                    label="Provider"
                    source={getSource("provider_id")}
                    record={scopedFormData}
                    disabled={!canEditEligibility(scopedFormData)}
                  />
                  {
                    canEditEligibility(scopedFormData) ? (
                      <Button
                        disabled={running}
                        onClick={() => removeEligibility(scopedFormData)}
                        sx={{marginTop: '4px', padding: '5px'}}
                      >
                        <RemoveCircleOutlineIcon sx={{color: 'rgb(237, 108, 2)'}} />
                      </Button>
                    ) : null
                  }
                </>
              )
            }
          }
        </FormDataConsumer>
      </SimpleFormIterator>
    </ReferenceManyInput>
  );
};

export const PatientEditForm = () => {
  const notify = useNotify();
  const redirect = useRedirect();
  const patient = useRecordContext();
  const dataProvider = useDataProvider();

  const onSubmit = async (data) => {
    const getReferencedByKey = (key, element_name) => {
      return data[key][0][element_name];
    };
    const getReferencedIdsByKey = (key, element_name) => {
      return getReferencedByKey(key, element_name).map((e) => e.id).filter((v) => v !== undefined && v !== null);
    };

    const upsert_patient_identifier = async () => {
      let identifierPromises = [];

      const { data: current_identifiers } = await dataProvider.getList(
        'patient_identifier', { filter: { patient_id: patient.id } }
      );

      const identifiers = getReferencedByKey(identifierKey(), "patient_identifier");
      const identifier_ids = getReferencedIdsByKey(identifierKey(), "patient_identifier");

      current_identifiers.forEach((identifier) => {
        if (!identifier.is_created_manually) {
          return;
        }
        else if (identifier_ids.indexOf(identifier.id) < 0) {
          identifierPromises.push(
            dataProvider.delete('patient_identifier', {id: identifier.id})
          );
        }
      });

      identifiers.forEach((identifier) => {
        if (!identifier.is_created_manually) {
          return;
        }
        else if (identifier.id === undefined) {
          identifierPromises.push(
            dataProvider.create('patient_identifier', {data: {...identifier, patient_id: patient.id}})
          );
        }
        else {
          identifierPromises.push(
            dataProvider.update('patient_identifier', {id: identifier.id, data: identifier})
          );
        }
      });

      return identifierPromises;
    };

    const upsert_patient_eligibility = async () => {
      let eligibilityPromises = [];

      const { data: current_eligibilities } = await dataProvider.getList(
        'patient_eligibility', { filter: { patient_id: patient.id } }
      );

      const eligibilities = getReferencedByKey(eligibilityKey(), "patient_eligibility");
      const eligibility_ids = getReferencedIdsByKey(eligibilityKey(), "patient_eligibility");

      current_eligibilities.forEach((eligibility) => {
        if (!eligibility.is_created_manually) {
          return;
        }
        else if (eligibility_ids.indexOf(eligibility.id) < 0) {
          eligibilityPromises.push(
            dataProvider.delete('patient_eligibility', {id: eligibility.id})
          );
        }
      });

      eligibilities.forEach((eligibility) => {
        if (!eligibility.is_created_manually) {
          return;
        }
        else if (eligibility.id === undefined) {
          eligibilityPromises.push(
            dataProvider.create('patient_eligibility', {data: {...eligibility, patient_id: patient.id}})
          );
        }
        else {
          eligibilityPromises.push(
            dataProvider.update('patient_eligibility', {id: eligibility.id, data: eligibility})
          );
        }
      });

      return eligibilityPromises;
    };

    const update_patient_values = async () => {
      let patientPromises = [];

      const eligibilities = getReferencedByKey(eligibilityKey(), "patient_eligibility");

      const today = (new Date()).setHours(0,0,0,0);
      data['is_active'] = eligibilities.find(
        (e) => (
          new Date(e.date_from).setHours(0,0,0,0) <= today &&
          today <= new Date(e.date_to).setHours(0,0,0,0)
        )
      ) !== undefined;

      delete data[identifierKey()];
      delete data[eligibilityKey()];
      patientPromises.push(
        dataProvider.update('patient', {id: patient.id, data: data})
      );

      return patientPromises;
    };

    const identifierPromises = await upsert_patient_identifier();
    const eligibilityPromises = await upsert_patient_eligibility();
    const patientPromises = await update_patient_values();
    const promises = [...new Set([...identifierPromises, ...eligibilityPromises, ...patientPromises])];

    Promise.allSettled(promises)
    .then(() => {
      notify('Patient updated', { type: 'success' });
    })
    .catch((e) => {
      notify('Error updating patient', { type: 'error' });
      console.log('error when updating patient');
      console.log(e)
    })
    .finally(() => {
      redirect('show', 'patient', patient.id);
    });
  };

  return (
    <SimpleForm toolbar={<PatientEditToolbar/>} onSubmit={onSubmit} validate={validatePatientSupervisorUpdate}>
      <TextInput label='Name' source="name" />
      <DateInput label='Date of birth' source="date_of_birth" />
      <PatientEditNames />
      <PatientEditIdentifiers />
      <PatientEditEligibility />
      <RichTextInput
        source="note"
        multiline
        fullWidth
      />
    </SimpleForm>
  );
};

export const PatientEdit = () => (
  <Edit title={<PatientTitle/>} redirect='show' mutationMode="optimistic">
    <PatientEditForm />
  </Edit>
);
export default PatientEdit;
