import {
  Show,
  SimpleShowLayout,
  TextField,
  ReferenceField,
  ReferenceManyField,
  Datagrid,
  TopToolbar,
  EditButton,
  Button,
  Link,
  Pagination,
  useRecordContext,
  useNotify,
} from "react-admin";
import {useState} from "react";
import {gql} from "graphql-tag";
import {useApolloClient} from "@apollo/client";
import VisibilityIcon from "@mui/icons-material/Visibility";
import {VITE_SENTRY_ENVIRONMENT} from "../config";
import RuleActionValues from "../ruleaction/RuleActionValues";
import RuleConditionValues from "../rulecondition/RuleConditionValues";
import CircularProgress from '@mui/material/CircularProgress';
import {VITE_DEFAULT_LISTSHOW_PER_PAGE} from "../config";

const RuleShowPatients = () => {
  const notify = useNotify();
  const record = useRecordContext();
  const apolloClient = useApolloClient();
  const [patientIds, setPatientIds] = useState(null);

  if (patientIds === null) {
    apolloClient.
      query({
        query: gql`
          query getRulePatientIds($values: GetRulePatientIdsInput!) {
            get_rule_patient_ids(values: $values) {
              patient_ids
            }
          }
        `,
        variables: {
          values: {
            "rule_id": record.id
          }
        },
        fetchPolicy: "network-only"
      }).
      then((response) => {
        setPatientIds(response["data"]["get_rule_patient_ids"]["patient_ids"]);
      }).
      catch((e) => {
        if (VITE_SENTRY_ENVIRONMENT === "local") {
          setPatientIds([]);
        }
        else {
          console.log(e);
          notify("Error recovering patients who comply with the rule", { type: "error" });
          throw e;
        }
      });
  }

  return (
    <Button
      label="Show Patients"
      component={Link}
      to="/patient"
      state={{ ids: patientIds }}
      children={<VisibilityIcon />}
      disabled={patientIds === null}
    >
      { patientIds === null && <CircularProgress color="inherit" size="18px" /> }
    </Button>
  );
}

const RuleShowActions = (props) => (
  <TopToolbar>
    <EditButton />
    <RuleShowPatients />
  </TopToolbar>
);

const RuleShow = () => (
  <Show actions={<RuleShowActions />}>
    <SimpleShowLayout>
      <TextField source="name"/>
      <ReferenceManyField
        label="Conditions"
        reference="rule_condition"
        source="id"
        target="rule_id"
        perPage={VITE_DEFAULT_LISTSHOW_PER_PAGE}
        pagination={<Pagination />}
      >
        <Datagrid rowClick={false} bulkActionButtons={false}>
          <ReferenceField source="class_id" reference="rule_condition_class">
            <TextField source="name" />
          </ReferenceField>
          <RuleConditionValues mode="list" label="Values" />
        </Datagrid>
      </ReferenceManyField>
      <ReferenceManyField
        label="Actions"
        reference="rule_action"
        source="id"
        target="rule_id"
        perPage={VITE_DEFAULT_LISTSHOW_PER_PAGE}
        pagination={<Pagination />}
      >
        <Datagrid rowClick={false} bulkActionButtons={false}>
          <ReferenceField source="type" reference="rule_action_type">
            <TextField source="description" />
          </ReferenceField>
          <RuleActionValues mode="list" label="Value" />
        </Datagrid>
      </ReferenceManyField>
    </SimpleShowLayout>
  </Show>
);

export default RuleShow;
