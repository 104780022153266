import {
  Datagrid,
  DateField,
  List,
  ReferenceField,
  TextField,
  DateInput,
} from "react-admin";
import PatientInput from "../patient/PatientInput";
import PatientNameReferenceField from "../patient/PatientNameReferenceField";
import ReportStateChipField from "./ReportStateChipField";
import {BulkDownloadReportsButton} from "./ReportShowButtons";
import {VITE_DEFAULT_LIST_PER_PAGE} from "../config";

const reconciliationReportProvPractitionerFilters = [
  <PatientInput label="Patient" source="patient_id" alwaysOn/>,
  <DateInput label="Next appointment from" source='next_appointment_date@_gte' />,
  <DateInput label="Next appointment to" source='next_appointment_date@_lte' />,
]

const ReconciliationReportProvPractitionerBulkActionButtons = props => (
  <>
    <BulkDownloadReportsButton {...props} />
  </>
);

export const ReconciliationReportProvPractitionerList = (props) => (
  <List
    exporter={false}
    hasCreate={false}
    perPage={VITE_DEFAULT_LIST_PER_PAGE}
    sort={{field: 'next_appointment_date', order: 'ASC'}}
    filters={reconciliationReportProvPractitionerFilters}
    {...props }
  >
    <Datagrid
      bulkActionButtons={<ReconciliationReportProvPractitionerBulkActionButtons/>}
      rowClick={(id, resource) => `/${resource}/${id}/pdf`}
    >
      <ReferenceField reference='provider_practitioner' source='provider_practitioner_id' link={false}>
        <TextField source='name'/>
      </ReferenceField>
      <DateField source="next_appointment_date"/>
      <PatientNameReferenceField link={false} />
      <ReferenceField
        label='Main identifier'
        reference='patient' source='patient_id' link={false}>
        <TextField source='current_eligibility.id_value'/>
      </ReferenceField>
      <ReportStateChipField source="state" label="Status" />
    </Datagrid>
  </List>
);

export default ReconciliationReportProvPractitionerList;
