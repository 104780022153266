import {
  Create,
  SimpleForm,
  TextInput,
  required,
} from "react-admin";

const RuleCreate = () => (
  <Create>
    <SimpleForm>
      <TextInput source="name" validate={required()} />
    </SimpleForm>
  </Create>
);

export default RuleCreate;
