import {
  List,
  Datagrid,
  ReferenceField,
  TextField,
  TopToolbar,
} from "react-admin";
import RuleConditionValues from "./RuleConditionValues";
import {VITE_DEFAULT_LIST_PER_PAGE} from "../config";

const RuleConditionListActions = () => (
  <TopToolbar>
  </TopToolbar>
);

const RuleConditionList = () => {
  return(
    <List
      actions={<RuleConditionListActions/>}
      exporter={false}
      perPage={VITE_DEFAULT_LIST_PER_PAGE}
    >
      <Datagrid rowClick={false} bulkActionButtons={false}>
        <ReferenceField source="rule_id" reference="rule" link={false}>
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField source="class_id" reference="rule_condition_class">
          <TextField source="name" />
        </ReferenceField>
        <RuleConditionValues mode="list" label="Values" />
      </Datagrid>
    </List>
  );
}

export default RuleConditionList;
