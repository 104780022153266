
export const diagnosisConditionsAggFormatter = (diagnosis) => {
  let v24s = diagnosis.conditions_v24.length ? diagnosis.conditions_v24 : '-';
  let v28s = diagnosis.conditions_v28.length ? diagnosis.conditions_v28 : '-';
  return `${v24s} / ${v28s}`;
}

export const cyToYear = (cy) => {
  switch (cy) {
    case 235:
      return '2020';
    case 245:
      return '2021';
    case 255:
      return '2022';
    case 260:
      return '2022-23';
    case 265:
      return '2023';
    case 270:
      return '2023-24';
    case 275:
      return '2024';
    case 280:
      return '2024-25';
    case 285:
      return '2025';
    case 290:
      return '2025-26';
    case 295:
      return '2026';
    default:
      return 'ERROR';
  }
}
