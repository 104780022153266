import {AppBar, Layout, TitlePortal} from 'react-admin';
import { CustomMenu } from './CustomMenu';
import inqoraLogo from './assets/inqora_full_word_white.png';
import { AppLocationContext } from '@react-admin/ra-navigation';

const CustomAppBar = (props) => {
  return <AppBar {...props}>
    <img
      src={inqoraLogo}
      alt='Inqora Logo'
      style={{
        height: '30px',
        marginLeft: '3px',
        marginRight: '15px',
      }}
    />
    <TitlePortal/>
  </AppBar>;
};

export const CustomLayout = (props) => (
  <AppLocationContext>
    <Layout {...props} menu={CustomMenu} appBar={CustomAppBar}/>
  </AppLocationContext>
);
