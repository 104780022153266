import {
  Edit,
  SimpleForm,
  ReferenceInput,
  AutocompleteInput,
  Toolbar,
  SaveButton,
  required,
} from "react-admin";
import RuleConditionValues from "./RuleConditionValues";

const RuleConditionEditToolbar = props => (
  <Toolbar {...props} >
    <SaveButton />
  </Toolbar>
);

const RuleConditionEdit = () => {
  const redirectFx = (resource, id, data) => {
    return `rule/${data.rule_id}`;
  }

  return (
    <Edit actions={false} redirect={redirectFx}>
      <SimpleForm toolbar={<RuleConditionEditToolbar />}>
        <ReferenceInput
          source="class_id"
          reference="rule_condition_class"
          sort={{ field: "name", order: "ASC" }}
        >
          <AutocompleteInput
            optionText="name"
            disabled={true}
            validate={required()}
            filterToQuery={(searchText) => ({ "name@_ilike": searchText })}
          />
        </ReferenceInput>
        <RuleConditionValues mode="edit" />
      </SimpleForm>
    </Edit>
  );
};

export default RuleConditionEdit;
