import {
  Button,
  EditButton,
  TopToolbar,
  useDataProvider,
  useListContext, useNotify, usePermissions,
  useRecordContext,
  useRedirect,
  useRefresh,
  useUnselectAll
} from "react-admin";
import {useState} from "react";
import {getPdfBlob} from "./renderPdfDocument";
import DownloadIcon from '@mui/icons-material/Download';
import SendIcon from '@mui/icons-material/Send';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import DraftsIcon from '@mui/icons-material/Drafts';
import {canAnswer, canDevolveToDraft, canEdit, canSend, canViewAnswer, canSendByEmail} from "./domain";
import {
  REC_OPEN_DIAGNOSES,
  RECONCILIATION_REPORT_QUERY,
  RECONCILIATION_REPORT_QUERY_DIAGNOSIS,
  RECONCILIATION_REPORT_QUERY_CONDITION,
  RECONCILIATION_REPORT_QUERY_CLINICAL_INDICATOR
} from "../constants";
import AddIcon from "@mui/icons-material/Add";
import { saveAs } from 'file-saver'
import EmailIcon from '@mui/icons-material/Email';
import { useApolloClient, gql } from '@apollo/client';


export const ReconciliationReportShowToolbar = (props) => {
  const record = useRecordContext(props);
  if(!record) return null;
  const state = record?.state;
  return (
    <TopToolbar>
      {canEdit(state) ? <EditButton /> : null}
      <AnswerReportButton />
      <MarkSentButton />
      <SendByEmailButton />
      <MarkDraftButton />
      <DownloadButton />
    </TopToolbar>
  )
}

export const ReconciliationReportAnswersToolbar = (props) => {
  const record = useRecordContext(props);
  if(!record) return null;
  return (
    <TopToolbar>
      <FinishedAnsweringReportButton />
    </TopToolbar>
  )
}

export const DownloadButton = () => {
  const report = useRecordContext();
  let [running, setRunning] = useState(false);

  let downloadPdf = async () => {
    // Must send the full object of reconciliation report to function. We don't have to fetch it again because we
    // already have it in the record context. We get the full object because the data provider is upgraded.
    let blob = await getPdfBlob(report);
    saveAs(blob, `Report ${report.patient.name} at ${report.next_appointment_date}.pdf`)
  }

  let handler = (event) => {
    event.stopPropagation();
    setRunning(true);
    downloadPdf()
      .catch((e) => {console.error(e)})
      .finally(() => {setRunning(false);});
  }

  return (
    <Button
      onClick={handler}
      disabled={running}
      label="Download"
      startIcon={<DownloadIcon/>}
    />
  );
};


export const BulkDownloadReportsButton = () => {
  const { selectedIds, resource } = useListContext();
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const [running, setRunning] = useState(false);

  const downloadPdf = async (report) => {
    // Must send the full object of reconciliation report to function. We don't have to fetch it again because we
    // already have it in the record context. We get the full object because the data provider is upgraded.
    let blob = await getPdfBlob(report);
    saveAs(blob, `Report ${report.patient.name} at ${report.next_appointment_date}.pdf`)
  }

  const downloadSelected = async () => {
    let promises = [];
    setRunning(true);

    for (let id of selectedIds) {
      const {data: report} = await dataProvider.getOne(resource, {id: id});
      const p = downloadPdf(report);
      promises.push(p);
    }
    Promise.allSettled(promises)
      .then(() => {
        notify(`All reports downloaded`, { type: 'success' });
      })
      .catch((e) => {
        notify(`Error generating PDF for report`, { type: 'error' });
        console.log('error when downloading report')
        console.log(e)
      })
      .finally(() => {
        setRunning(false);
      });
  };

  return (
    <Button
      label="Download all"
      disabled={running}
      onClick={() => downloadSelected()}
    >
      <DownloadIcon />
    </Button>
  );
}

export const MarkDraftButton = () => {
  const report = useRecordContext();
  const refresh = useRefresh();
  const dataProvider = useDataProvider();
  const { isLoadingPermissions, permissions } = usePermissions();
  let [running, setRunning] = useState(false);

  if (isLoadingPermissions || !permissions.includes('supervisor') || !canDevolveToDraft(report.state)) {
    return null;
  }

  let handleClick = (event) => {
    event.stopPropagation();
    setRunning(true);
    dataProvider.update('reconciliation_report', {id: report.id, data: {state: 'draft'}})
      .then(() => {
        refresh();
      })
      .finally(() => {
        setRunning(false);
      })
  }

  return (
    <Button
      startIcon={<DraftsIcon/>}
      onClick={handleClick}
      disabled={running}
      label="Back to Draft"/>
  );
}


export const MarkSentButton = () => {
  const report = useRecordContext();
  const refresh = useRefresh();
  const dataProvider = useDataProvider();
  let [running, setRunning] = useState(false);

  if (!canSend(report.state)) {
    return null;
  }

  let handleSend = (event) => {
    event.stopPropagation();
    setRunning(true);
    dataProvider.update('reconciliation_report', {id: report.id, data: {state: 'sent'}})
      .then(() => {
        refresh();
      })
      .finally(() => {
        setRunning(false);
      })
  }

  return (
    <Button
      startIcon={<SendIcon/>}
      onClick={handleSend}
      disabled={running}
      label="Mark Sent"/>
  );
}

export const SendByEmailButton = () => {
  const report = useRecordContext();
  const refresh = useRefresh();
  const apolloClient = useApolloClient();
  let [running, setRunning] = useState(false);

  if (!canSendByEmail(report)) {
    return null;
  }

  const handleSendByEmail = (event) => {
    event.stopPropagation();
    setRunning(true);
    apolloClient.mutate({
      mutation: gql`
        mutation sendReconciliationReportsByEmail($ids: [Int]!) {
          send_reconciliation_reports_by_email(ids: $ids) {
            message
          }
        }
      `,
      variables: {
        ids: [report.id]
      }
    }).then(() => {
      refresh();
    }).finally(() => {
      setRunning(false);
    });
  }

  return (
    <Button
      startIcon={<EmailIcon/>}
      onClick={handleSendByEmail}
      disabled={running}
      label="Send by email"/>
  );
}

export const BulkMarkSentReportsButton = () => {
  const { selectedIds, resource } = useListContext();
  const refresh = useRefresh();
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const [running, setRunning] = useState(false);

  const markSentSelected = async () => {
    let promises = [];
    setRunning(true);

    for (let id of selectedIds) {
      const {data: report} = await dataProvider.getOne(resource, {id: id});

      if (canSend(report.state)) {
        const p = dataProvider.update('reconciliation_report', {id: report.id, data: {state: 'sent'}});
        promises.push(p);
      }
    }
    Promise.allSettled(promises)
      .then(() => {
        notify(`All draft reports marked as sent`, { type: 'success' });
      })
      .catch((e) => {
        notify(`Error marking reports as sent`, { type: 'error' });
        console.log('error marking reports as sent')
        console.log(e)
      })
      .finally(() => {
        refresh();
        setRunning(false);
      });
  };

  return (
    <Button
      label="Mark all as sent"
      disabled={running}
      onClick={() => markSentSelected()}
    >
      <SendIcon />
    </Button>
  );
}

export const BulkSentReportsByEmailButton = () => {
  const { selectedIds, resource } = useListContext();
  const refresh = useRefresh();
  const apolloClient = useApolloClient();
  const notify = useNotify();
  const [running, setRunning] = useState(false);

  const sendByEmailSelected = async () => {
    setRunning(true);
    apolloClient.mutate({
      mutation: gql`
        mutation sendReconciliationReportsByEmail($ids: [Int]!) {
          send_reconciliation_reports_by_email(ids: $ids) {
            message
          }
        }
      `,
      variables: {
        ids: selectedIds
      }
    })
    .then(() => {
      notify(`All reports sent by email`, { type: 'success' });
    })
    .catch((e) => {
      notify(`Error sending reports by email`, { type: 'error' });
      console.log('error sending reports by email')
      console.log(e)
    })
    .finally(() => {
      refresh();
      setRunning(false);
    });
  };

  return (
    <Button
      label="Send all by email"
      disabled={running}
      onClick={() => sendByEmailSelected()}
    >
      <EmailIcon />
    </Button>
  );
}

export const AnswerReportButton = () => {
  const record = useRecordContext();
  const redirect = useRedirect();
  if (!canViewAnswer(record.state)){
    return null
  }

  let handler = () => {
    redirect(`/reconciliation_report/${record.id}/answers`);
  }

  return <Button
    startIcon={<QuestionAnswerIcon/>}
    label="Answer"
    onClick={handler}
  />
}

export const FinishedAnsweringReportButton = () => {
  const record = useRecordContext();
  const refresh = useRefresh();
  const dataProvider = useDataProvider();
  let [running, setRunning] = useState(false);

  if (!canAnswer(record.state)){
    return null
  }
  const isDisabled = running || record.state !== 'sent';

  let handleAnswered = (event) => {
    event.stopPropagation();
    setRunning(true);
    dataProvider.update('reconciliation_report', {id: record.id, data: {state: 'answered'}})
      .then(() => {
        refresh();
      })
      .finally(() => {
        setRunning(false);
      })
  }

  return <Button
    startIcon={<QuestionAnswerIcon/>}
    label="Finished answering"
    onClick={handleAnswered}
    disabled={isDisabled}
  />
}
// ---------------------
// Bulk action buttons
// ---------------------

/**
 Bulk add past diagnosis to report button.
 */
export const BulkAddConditionQueryButton = (props) => {
  const record = useRecordContext();
  let reportId = record.id;
  const [running, setRunning] = useState(false);
  const { selectedIds, resource } = useListContext();
  const unselectAll = useUnselectAll(`reconciliation_report.${reportId}.${REC_OPEN_DIAGNOSES}`);
  const dataProvider = useDataProvider();
  const refresh = useRefresh();
  if(!record) return null;
  if (!canEdit(record.state)) return null;

  const createSelected = async () => {
    let promises = [];
    setRunning(true);
    for (let id of selectedIds) {
      const {data: openDiagnosis} = await dataProvider.getOne(resource, {id: id});
      const entry = {
        report_id: reportId,
        query_kind: 'yesno',
        latest_service_provider_id: openDiagnosis.latest_serv_prov,
        latest_service_date: openDiagnosis.latest_serv_date,
        query_text: ''
      };
      const p = dataProvider.create(RECONCILIATION_REPORT_QUERY,{data: entry})
        .then((result) => {
          const reportQuery = result.data;
          const entry_d = {
            reconciliation_report_query_id: reportQuery.id,
            diagnosis_id: openDiagnosis.diagnosis_id,
            diagnosis_description: openDiagnosis.diag_description
          };
          return dataProvider.create(
            RECONCILIATION_REPORT_QUERY_DIAGNOSIS,
            {data: entry_d}
          )
        })
        .then(() => {
          // console.log('queryDiagnosis');
          // console.log(result.data);
        });
      promises.push(p);

      Promise.all(promises)
        .then(() => {
          props.refresh();
        })
        .catch((e) => {
          console.log('error when adding to report')
          console.log(e)
        })
        .finally(() => {
          setRunning(false);
          unselectAll();
          refresh();
        });
    }
  };

  return (
    <Button
      label="Add as diagnosis query(es)"
      disabled={running}
      onClick={() => createSelected()}
    >
      <AddIcon />
    </Button>
  );
};



export const BulkAddClinicalIndicatorQueryButton = (props) => {
  const record = useRecordContext();
  let reportId = record.id;
  const [running, setRunning] = useState(false);
  const { selectedIds, resource } = useListContext();
  const unselectAll = useUnselectAll(`reconciliation_report.${reportId}.${REC_OPEN_DIAGNOSES}`);
  const dataProvider = useDataProvider();
  const refresh = useRefresh();
  if(!record) return null;
  if (!canEdit(record.state)) return null;

  const createSelected = async () => {
    let promises = [];
    setRunning(true);

    const entry = {
      report_id: reportId,
      query_kind: 'diagnosis',
      latest_service_provider_id: null,
      query_text: ''
    };

    let reportQuery = await dataProvider.create(RECONCILIATION_REPORT_QUERY,{data: entry});
    reportQuery = reportQuery.data;

    for (let id of selectedIds) {
      const {data: openDiagnosis} = await dataProvider.getOne(resource, {id: id});

      const entry_d = {
        reconciliation_report_query_id: reportQuery.id,
        diagnosis_id: openDiagnosis.diagnosis_id,
        diagnosis_description: openDiagnosis.diag_description
      };

      promises.push(dataProvider.create(
        RECONCILIATION_REPORT_QUERY_DIAGNOSIS,
        {data: entry_d}
      ));
    }

    Promise.all(promises)
      .then(() => {
        props.refresh();
      })
      .catch((e) => {
        console.log('error when adding to report')
        console.log(e)
      })
      .finally(() => {
        setRunning(false);
        unselectAll();
        refresh();
      });
  };

  return (
    <Button
      label="Add as clinical indicators query"
      disabled={running}
      onClick={() => createSelected()}
    >
      <AddIcon />
    </Button>
  );
};


/**
 Bulk add smart queries to report button.
 */
export const BulkAddSmartQueryButton = (props) => {
  const record = useRecordContext();
  let reportId = record.id;
  const [running, setRunning] = useState(false);
  const { selectedIds, resource } = useListContext();
  const unselectAll = useUnselectAll(`reconciliation_report.${reportId}.smart_query`);
  const dataProvider = useDataProvider();
  const refresh = useRefresh();
  if(!record) return null;
  if (!canEdit(record.state)) return null;

  const createSelected = async () => {
    let promises = [];
    setRunning(true);
    for (let id of selectedIds) {
      const {data: smartQuery} = await dataProvider.getOne(resource, {id: id});
      const {data: smartDiagnoses} = await dataProvider.getList(
        'smart_query_diagnosis',
        { filter: { smart_query_id: smartQuery.id } }
      );
      const {data: smartConditions} = await dataProvider.getList(
        'smart_query_condition',
        { filter: { smart_query_id: smartQuery.id } }
      );
      const {data: smartClinicalIndicators} = await dataProvider.getList(
        'smart_query_clinical_indicator',
        { filter: { smart_query_id: smartQuery.id } }
      );

      const entry = {
        report_id: reportId,
        query_kind: 'smart',
        latest_service_provider_id: null,
        query_text: ''
      };
      const p = dataProvider.create(RECONCILIATION_REPORT_QUERY,{data: entry})
        .then((result) => {
          const reportQuery = result.data;

          // Create Diagnoses
          for (const indexD in smartDiagnoses) {
            const smartDiagnosis = smartDiagnoses[indexD];
            const entry_d = {
              reconciliation_report_query_id: reportQuery.id,
              diagnosis_id: smartDiagnosis.diagnosis_id,
              diagnosis_description: smartDiagnosis.diagnosis_description
            };
            promises.push(dataProvider.create(
              RECONCILIATION_REPORT_QUERY_DIAGNOSIS,
              {data: entry_d}
            ));
          }

          // Create Conditions
          for (const indexC in smartConditions) {
            const smartCondition = smartConditions[indexC];
            const entry_c = {
              reconciliation_report_query_id: reportQuery.id,
              condition_id: smartCondition.condition_id,
              condition_description: smartCondition.condition_description
            };
            promises.push(dataProvider.create(
              RECONCILIATION_REPORT_QUERY_CONDITION,
              {data: entry_c}
            ));
          }

          // Create Clinical Indicators
          for (const indexCI in smartClinicalIndicators) {
            const smartClinicalIndicator = smartClinicalIndicators[indexCI];
            const entry_ci = {
              reconciliation_report_query_id: reportQuery.id,
              clinical_indicator: smartClinicalIndicator.clinical_indicator,
              value: smartClinicalIndicator.value,
              reference_op: smartClinicalIndicator.reference_op,
              reference_date: smartClinicalIndicator.reference_date
            };
            promises.push(dataProvider.create(
              RECONCILIATION_REPORT_QUERY_CLINICAL_INDICATOR,
              {data: entry_ci}
            ));
          }
        });

      Promise.all(promises)
        .then(() => {
          props.refresh();
        })
        .catch((e) => {
          console.log('error when adding to report')
          console.log(e)
        })
        .finally(() => {
          setRunning(false);
          unselectAll();
          refresh();
        });
    }
  };

  return (
    <Button
      label="Add as smart query(es)"
      disabled={running}
      onClick={() => createSelected()}
    >
      <AddIcon />
    </Button>
  );
};
