import {
  List,
  Pagination,
  Datagrid,
  TextField,
  DateField,
  ReferenceField,
  ReferenceArrayInput,
  SelectArrayInput,
  EditButton,
  downloadCSV,
} from 'react-admin';
import jsonExport from 'jsonexport/dist';
import PatientInput from '../patient/PatientInput';
import DiagnosisInput from '../claim/DiagnosisInput';
import GatekeeperInput from '../providerpractitioner/GatekeeperInput';
import PatientNameReferenceField from '../patient/PatientNameReferenceField';
import GatekeeperReferenceField from '../gatekeeper/GatekeeperReferenceField';
import {VITE_DEFAULT_LIST_PER_PAGE} from "../config";


const gapFilters = [
  <ReferenceArrayInput
    label='Status'
    source='status'
    reference='gap_status'
    sort={{ field: 'description', order: 'ASC' }}
    alwaysOn
  >
    <SelectArrayInput label='Status' optionText='description' />
  </ReferenceArrayInput>,
  <PatientInput label='Patient' source='patient_id' name='patient_id' alwaysOn/>,
  <GatekeeperInput label='PCP' source='patient#current_eligibility#gatekeeper_id'/>,
  <DiagnosisInput source='diag_code' reference='diagnosis' label='Diagnosis'/>,
  <ReferenceArrayInput
    label='Disposition'
    source='working_status'
    reference='gap_working_status'
    sort={{ field: 'description', order: 'ASC' }}
  >
    <SelectArrayInput label='Disposition' optionText='description' />
  </ReferenceArrayInput>,
];

const gapExporter = (gaps, fetchRelatedRecords) => {
  let promises = [
    fetchRelatedRecords(
      gaps.map(gap => gap.patient.current_eligibility), 'gatekeeper_id', 'gatekeeper'
    ),
    fetchRelatedRecords(
      gaps.map(gap => gap.patient.current_eligibility), 'insurance_provider_id', 'insurance_provider'
    ),
    fetchRelatedRecords(gaps, 'status', 'gap_status'),
    fetchRelatedRecords(gaps, 'working_status', 'gap_working_status'),
    fetchRelatedRecords(gaps, 'source_claim_id', 'claim'),
    fetchRelatedRecords(gaps, 'latest_claim_id', 'claim'),
    fetchRelatedRecords(gaps, 'diag_code', 'diagnosis_conditions_agg'),
  ];
  Promise.allSettled(promises).then(response => {
    const gatekeepers = response[0]?.value;
    const insuranceProviders = response[1]?.value;
    const gapStates = response[2]?.value;
    const gapWorkingStates = response[3]?.value;
    const sourceClaims = response[4]?.value;
    const latestClaims = response[5]?.value;
    const hccs = response[6]?.value;
    const gapsExpanded = gaps.map(gap => {
      const gatekeeper = gatekeepers[gap.patient.current_eligibility.gatekeeper_id];
      const insuranceProvider = insuranceProviders[gap.patient.current_eligibility.insurance_provider_id];
      const gapStatus = gapStates[gap.status];
      const gapWorkingStatus = gapWorkingStates[gap.working_status];
      const sourceClaim = sourceClaims[gap.source_claim_id];
      const latestClaim = latestClaims[gap.latest_claim_id];
      const hcc = hccs[gap.diag_code];
      return ({
        ...gap,
        member_id: gap.patient.current_eligibility.id_value,
        member_name: gap.patient.name,
        member_dob: gap.patient.date_of_birth,
        pcp: gatekeeper === undefined ? '' : gatekeeper.name,
        network: insuranceProvider === undefined ? '' : insuranceProvider.description,
        type: gapStatus === undefined ? '' : gapStatus.description,
        disposition: gapWorkingStatus === undefined ? '' : gapWorkingStatus.description,
        source: sourceClaim === undefined ? '' : sourceClaim.claim_nbr,
        dos: latestClaim === undefined ? '' : latestClaim.min_service_date,
        hcc_v24: hcc === undefined ? '' : hcc.conditions_v24,
        hcc_v28: hcc === undefined ? '' : hcc.conditions_v28,
      });
    });
    const gapsForExport = gapsExpanded.map(gap => {
      const {
        id,
        patient_id,
        year,
        status,
        working_status,
        assigned_to,
        source_claim_id,
        latest_claim_id,
        updated_by,
        patient,
        source_claim,
        latest_claim,
        ...gapForExport
      } = gap;
      return gapForExport;
    });
    jsonExport(gapsForExport, {
        headers: ['member_id', 'member_name', 'member_dob', 'pcp', 'network', 'diag_code',
                  'hcc_v24', 'hcc_v28', 'type', 'dos', 'source', 'disposition', 'comment']
    }, (err, csv) => {
        downloadCSV(csv, 'gaps');
    });
  });
};

const GapList = () => (
  <List
    exporter={gapExporter}
    pagination={<Pagination/>}
    perPage={VITE_DEFAULT_LIST_PER_PAGE}
    filter={{
      'year': new Date().getFullYear(),
      'patient#is_active': true
    }}
    filters={gapFilters}
  >
    <Datagrid rowClick='show' bulkActionButtons={false}>
      <TextField source='patient.current_eligibility.id_value' label='Member ID' />
      <PatientNameReferenceField label='Member Name' />
      <TextField source='patient.date_of_birth' label='DOB' />
      <GatekeeperReferenceField source='patient.current_eligibility.gatekeeper_id' label='PCP' />
      <ReferenceField
        reference='insurance_provider'
        source='patient.current_eligibility.insurance_provider_id'
        label='Network'
      >
        <TextField source='description'/>
      </ReferenceField>
      <TextField source='diag_code' label='Diagnosis Code' />
      <ReferenceField
        label='HCC v24'
        source='diag_code'
        reference='diagnosis_conditions_agg'
      >
        <TextField source='conditions_v24'/>
      </ReferenceField>
      <ReferenceField
        label='HCC v28'
        source='diag_code'
        reference='diagnosis_conditions_agg'
      >
        <TextField source='conditions_v28'/>
      </ReferenceField>
      <ReferenceField
        reference='gap_status'
        source='status'
        label='Type'
        link={false}
      >
        <TextField source='description' />
      </ReferenceField>
      <ReferenceField
        reference='claim'
        source='latest_claim_id'
        label='DOS'
        link='show'
      >
        <DateField source='min_service_date' />
      </ReferenceField>
      <ReferenceField
        reference='claim'
        source='source_claim_id'
        label='Source'
        link='show'
      >
        <TextField source='claim_nbr' />
      </ReferenceField>
      <ReferenceField
        reference='gap_working_status'
        source='working_status'
        label='Disposition'
        link={false}
      >
        <TextField source='description' />
      </ReferenceField>
      <EditButton />
    </Datagrid>
  </List>
);

export default GapList;
