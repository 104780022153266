import {SearchInput, List, Datagrid, TextField, ShowButton, EditButton} from "react-admin";
import {VITE_DEFAULT_LIST_PER_PAGE} from "../config";

const gatekeeperFilters = [
  <SearchInput source="name" alwaysOn/>
];

const GatekeeperList = () => (
  <List
    filters={gatekeeperFilters}
    perPage={VITE_DEFAULT_LIST_PER_PAGE}
  >
    <Datagrid bulkActionButtons={false}>
      <TextField source="name" />
      <TextField source="tax_id"/>
      <TextField source="npi"/>
      <ShowButton />
      <EditButton />
    </Datagrid>
  </List>
);

export default GatekeeperList;