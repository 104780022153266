import {
  Button,
  Pagination,
  ReferenceField,
  ReferenceManyField,
  Show,
  TabbedShowLayout,
  TextField,
  TopToolbar,
  useRecordContext,
  EditButton,
  RichTextField,
  usePermissions, SimpleShowLayout,
} from "react-admin";
import PatientTitle from "./PatientTitle";
import PatientEligibilityListField from "./PatientEligibilityListField";
import PatientShowDiagnosisTab from "./PatientShowDiagnosisTab";
import PatientShowHCCTab from "./PatientShowHCCTab";
import {ReconciliationReportListView} from "../reconciliationreport/ReconciliationReportList";
import {Link} from "react-router-dom";
import {ClaimListView} from "../claim/ClaimList";
import ClaimIcon from '@mui/icons-material/Assignment';
import FactCheckIcon from "@mui/icons-material/FactCheck";
import EditIcon from '@mui/icons-material/Edit';
import CreateReportWithPatientButton from "./CreateReportWithPatientButton";
import PatientShowLabTab from "./PatientShowLabTab";
import PatientShowPrxTab from "./PatientShowPrxTab";
import {SapiensFieldsRow} from "../components/SapiensFieldsRow";
import PatientShowHedisTab from "./PatientShowHedisTab";
import GatekeeperReferenceField from "../gatekeeper/GatekeeperReferenceField";
import {VITE_DEFAULT_LISTSHOW_PER_PAGE} from "../config";


const GoToClaimsButton = ({patientId, ...rest}) => {
  const record = useRecordContext();
  const id = patientId || record?.id;
  return (
    <Button label='View all patient claims' component={Link}
            to={`/claim?filter=${JSON.stringify({patient_id: id})}`}
            startIcon={<ClaimIcon/>}
            {...rest}
    />
  );
}


const GoToReportsButton = ({patientId, ...rest}) => {
  const record = useRecordContext();
  const id = patientId || record?.id;
  return (
    <Button label='View all patient reports' component={Link}
            to={`/reconciliation_report?filter=${JSON.stringify({patient_id: id})}`}
            startIcon={<FactCheckIcon/>}
            {...rest}
    />
  );
}


const PatientShowTopToolbar = () => {
  const record = useRecordContext();
  const { permissions } = usePermissions();
  if (permissions === undefined) return null;

  return (
    <TopToolbar>
      {
        permissions.includes('supervisor') ?
        <Button
          label="Edit"
          component={Link}
          to={`/patient/${record?.id}/edit`}
          startIcon={<EditIcon/>}
        /> :
        <EditButton/>
      }
      <CreateReportWithPatientButton />
    </TopToolbar>
  )
}

export const PatientShow = () => {
  return (
    <Show title={<PatientTitle/>} actions={<PatientShowTopToolbar />}>

        <SimpleShowLayout>
          <SapiensFieldsRow>
            <ReferenceField reference='insurance_provider' source='current_eligibility.insurance_provider_id' label='Insurance provider'>
              <TextField source='description'/>
            </ReferenceField>
            <GatekeeperReferenceField label='Primary care physician' source="current_eligibility.gatekeeper_id" emptyText='Empty' />
            <ReferenceField label='Line of business' source="current_eligibility.lob_id" reference="line_of_business">
              <TextField source="description" />
            </ReferenceField>
            <ReferenceManyField label="Eligibility dates" reference="patient_eligibility" target="patient_id">
              <PatientEligibilityListField/>
            </ReferenceManyField>
            <TextField label="HCC score 2023 (265)" source="hcc_score_previous_whole_year"/>
            <TextField label="HCC score 2023-2024 (270)" source="hcc_score_half_year"/>
            <TextField label="HCC score 2024 (275)" source="hcc_score_current_whole_year"/>
          </SapiensFieldsRow>
          <RichTextField
            label="Notes"
            source="note"
          />
        </SimpleShowLayout>
      <TabbedShowLayout>
        <TabbedShowLayout.Tab label="Diagnoses">
          <PatientShowDiagnosisTab />
        </TabbedShowLayout.Tab>
        <TabbedShowLayout.Tab label="HCC" path='hcc'>
          <PatientShowHCCTab />
        </TabbedShowLayout.Tab>
        <TabbedShowLayout.Tab label="Reports" path='reports'>
          <GoToReportsButton />
          <ReferenceManyField
            label="Reconciliation reports for patient"
            reference="reconciliation_report"
            target="patient_id"
            perPage={50}
            pagination={<Pagination/>}
            sort={{field: 'created_at', order: 'DESC'}}
          >
            <ReconciliationReportListView label="Patients reports" bulkActionButtons={false}/>
          </ReferenceManyField>
        </TabbedShowLayout.Tab>
        <TabbedShowLayout.Tab label="Claims" path='claims'>
          <GoToClaimsButton />
          <ReferenceManyField
            label="Recent claims for patient"
            reference='claim'
            target='patient_id'
            perPage={50}
            pagination={<Pagination/>}
            sort={{field: 'min_service_date', order: 'DESC'}}
          >
              <ClaimListView />
          </ReferenceManyField>
        </TabbedShowLayout.Tab>
        <TabbedShowLayout.Tab label="Lab" path='lab'>
            <PatientShowLabTab />
        </TabbedShowLayout.Tab>
        <TabbedShowLayout.Tab label="Prx" path='prx'>
          <PatientShowPrxTab />
        </TabbedShowLayout.Tab>
        <TabbedShowLayout.Tab label="Hedis" path='hedis'>
          <PatientShowHedisTab />
        </TabbedShowLayout.Tab>
      </TabbedShowLayout>
    </Show>
  );
}


export default PatientShow;
