import {
  ReferenceInput,
  SelectInput,
  TextInput,
  DateInput,
  List,
  Datagrid,
  ReferenceField,
  TextField,
  DateField
} from 'react-admin';

import GatekeeperInput from "../providerpractitioner/GatekeeperInput";
import OfficeInput from "../office/OfficeInput";
import UserInput from "../user/UserInput";
import GatekeeperReferenceField from "../gatekeeper/GatekeeperReferenceField";
import OfficeReferenceField from "../office/OfficeReferenceField";
import {VITE_DEFAULT_LIST_PER_PAGE} from "../config";

const dataFileFilters = [
  <TextInput label="File name" source="file_name"/>,
  <ReferenceInput label="Status" source="status" reference="data_file_status" sort={{ field: "description", order: "ASC" }}>
    <SelectInput optionText="description" />
  </ReferenceInput>,
  <ReferenceInput label="Class" source="file_class" reference="data_file_class" sort={{ field: "description", order: "ASC" }}>
    <SelectInput optionText="description" />
  </ReferenceInput>,
  <GatekeeperInput source="gatekeeper_id" />,
  <OfficeInput source="office_id" />,
  <SelectInput label="Bucket name" source="bucket_name" choices={[
    { id: "rma-dev", name: "rma-dev" },
    { id: "healthplandata-rma", name: "healthplandata-rma" },
    { id: "healthplandata-fha", name: "healthplandata-fha" },
  ]}/>,
  <TextInput label="Aws key" source="aws_key"/>,
  <DateInput label="Created from" source="created_at@_gte" parse={v => {let d = new Date(`${v}T00:00:00`); return d.toISOString()}}/>,
  <DateInput label="Created to" source="created_at@_lte" parse={v => {let d = new Date(`${v}T23:59:59`); return d.toISOString()}}/>,
  <UserInput label="User" source="user_id" />,
];

const DataFileList = () => (
  <List
    exporter={false}
    perPage={VITE_DEFAULT_LIST_PER_PAGE}
    sort={{field: 'created_at', order: 'DESC'}}
    filters={dataFileFilters}
  >
    <Datagrid bulkActionButtons={false}>
      <TextField source="file_name" />
      <ReferenceField source="status" reference="data_file_status">
        <TextField source="description" />
      </ReferenceField>
      <ReferenceField label="Class" source="file_class" reference="data_file_class">
        <TextField source="description" />
      </ReferenceField>
      <GatekeeperReferenceField />
      <OfficeReferenceField />
      <TextField source="bucket_name" />
      <TextField source="aws_key" />
      <ReferenceField reference="user" source="user_id" link={false}>
        <TextField source="name" />
      </ReferenceField>
      <DateField source="created_at"/>
    </Datagrid>
  </List>
);

export default DataFileList;
