import {
  Show,
  SimpleShowLayout,
  TextField,
  ReferenceManyField,
  Datagrid,
  TopToolbar,
  ShowButton,
  EditButton,
  Labeled,
  ArrayField,
  usePermissions,
  useRecordContext,
  useDataProvider,
  Pagination
} from "react-admin";
import { useState } from 'react';
import {VITE_DEFAULT_LISTSHOW_PER_PAGE} from "../config";

const GatekeeperShowTopToolbar = () => {
  const { permissions } = usePermissions();
  if (permissions === undefined) return null;

  return (
    <TopToolbar>
      { permissions.includes('supervisor') ? <EditButton/> : null }
    </TopToolbar>
  )
}

const GatekeeperShowInsurances = () => {
  const record = useRecordContext();
  const dataProvider = useDataProvider();
  const [insurances, setInsurances] = useState(null);

  if (insurances === null && record !== null) {
    dataProvider.getManyReference(
        'setting',
        {
          target: 'gatekeeper_id',
          id: record.id
        }
      )
      .then((r) => {
        setInsurances(
          r.data.
            filter((item) => item.code === 'insurances')[0]?.
            insurances_list?.
            map((value) => ({value}))
        );
      });
  }

  return (
    <Labeled fullWidth>
      <ArrayField
        source="insurances"
        record={{insurances: insurances}}
        label="Insurances for appointments"
        perPage={VITE_DEFAULT_LISTSHOW_PER_PAGE}
      >
        <Datagrid rowClick={false} bulkActionButtons={false}>
          <TextField source="value" />
        </Datagrid>
        <Pagination />
      </ArrayField>
    </Labeled>
  );
};

const GatekeeperShow = () => (
  <Show actions={<GatekeeperShowTopToolbar />}>
    <SimpleShowLayout>
      <TextField source="name"/>
      <TextField source="tax_id"/>
      <TextField source="npi"/>
      <TextField source="ehr"/>
      <ReferenceManyField
        label="Offices"
        reference="office"
        source="id"
        target="gatekeeper_id"
        sort={{field: "name", order: "DESC"}}
        perPage={VITE_DEFAULT_LISTSHOW_PER_PAGE}
        pagination={<Pagination />}
      >
        <Datagrid rowClick={false} bulkActionButtons={false}>
          <TextField source="name"/>
          <ShowButton />
          <EditButton />
        </Datagrid>
      </ReferenceManyField>
      <GatekeeperShowInsurances />
    </SimpleShowLayout>
  </Show>
);

export default GatekeeperShow;