
import {
  Datagrid,
  FunctionField,
  ReferenceField,
  ReferenceManyField,
  Show,
  SimpleShowLayout,
  TextField,
  TopToolbar,
  EditButton,
  useRecordContext,
  Pagination,
} from "react-admin";
import {useState} from "react";
import {getUserId} from "../auth/sapiensUser";
import {diagnosisConditionsAggFormatter} from "../utils";
import {ClaimShow, CustomBreadcrumb} from "./components";
import AuditClaimStatusField from "./AuditClaimStatusField";
import {VITE_DEFAULT_LISTSHOW_PER_PAGE} from "../config";


const AuditClaimShowActions = (props) => {
  const record = useRecordContext();
  const [currentUserId, setCurrentUserId] = useState(null);

  if (currentUserId === null) {
    getUserId().then((id) => {
      setCurrentUserId(parseInt(id));
    });
  }

  return (
    <TopToolbar>
      { (currentUserId !== null && record.audit.status === 'started' && record.audit.user_id === currentUserId) ? <EditButton /> : null}
    </TopToolbar>
  );
}

const AuditClaimShow = (props) => {
  return (
    <Show actions={<AuditClaimShowActions />}>
      <CustomBreadcrumb/>
      <SimpleShowLayout>
        <ReferenceField label='Auditor' reference='user' source='audit.user_id' link={false}>
          <TextField source='name'/>
        </ReferenceField>
      </SimpleShowLayout>
      <ClaimShow relatedClaimsLink='show'/>
      <SimpleShowLayout>
        <AuditClaimStatusField/>
        <ReferenceManyField
          reference='audit_claim_diagnosis'
          target='audit_claim_id'
          sort={{field: 'kind,diagnosis_id', order: 'ASC,ASC'}}
          perPage={VITE_DEFAULT_LISTSHOW_PER_PAGE}
          pagination={<Pagination />}
        >
          <Datagrid bulkActionButtons={false} rowClick={false}>
            <ReferenceField reference='diagnosis' source="diagnosis_id" label="Diagnosis" link={false}>
              <TextField source="id"/>
            </ReferenceField>
            <ReferenceField reference='diagnosis' source="diagnosis_id" label="Diagnosis " link={false}>
              <TextField source="description"/>
            </ReferenceField>
            <ReferenceField reference="diagnosis_conditions_agg" source="diagnosis_id" label={<div>HCC<br/>v24/v28</div>}>
              <FunctionField render={(record) => diagnosisConditionsAggFormatter(record)}/>
            </ReferenceField>
            <TextField source="result" label="Result"/>
            <TextField source="comment" label="Comment"/>
          </Datagrid>
        </ReferenceManyField>
      </SimpleShowLayout>
    </Show>
  );
};

export default AuditClaimShow;
