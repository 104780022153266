import {
  Show,
  SimpleShowLayout,
  ReferenceField,
  BooleanField,
  FunctionField,
  ReferenceManyField,
  Datagrid,
  Pagination,
  TextField,
  DateField,
  Button,
  useRecordContext,
  useDataProvider,
  useGetOne,
} from 'react-admin';
import {useState} from 'react';
import {LinearProgress} from '@mui/material';
import {Link} from 'react-router-dom';

import EnrichedAppointmentDateField from "./EnrichedAppointmentDateField";
import OfficeReferenceField from "../office/OfficeReferenceField";
import PatientNameReferenceField from '../patient/PatientNameReferenceField';
import ReportStateChipField from "../reconciliationreport/ReportStateChipField";
import FactCheckIcon from '@mui/icons-material/FactCheck';
import {VITE_DEFAULT_LISTSHOW_PER_PAGE} from "../config";

const AppointmentProvider = ({label, ...rest}) => {
  const record = useRecordContext();

  return (
    (record.provider_practitioner_id !== null) ?
    (
      <ReferenceField
        source="provider_practitioner_id"
        reference="provider_practitioner"
      >
        <TextField label={label} source="name" />
      </ReferenceField>
    ) :
    <TextField label={label} source="provider_name" />
  )
}

const AppointmentPatient = ({label, ...rest}) => {
  const record = useRecordContext();

  return (
    (record.patient_id !== null) ?
      <PatientNameReferenceField label={label} /> :
      <TextField label={label} source="patient_name" />
  );
}

const AppointmentReport = ({label, ...rest}) => {
  const record = useRecordContext();

  record.reconciliation_report_state = '';
  if (record.reconciliation_report_id !== null) {
    const {data: report, isLoading, error} = useGetOne(
      'reconciliation_report',
      {id: record.reconciliation_report_id}
    );
    record.reconciliation_report_state = (report !== undefined) ? report.state : '';
  }

  return (
    record.archived ? null : (
      record.reconciliation_report_id === null ?
        null :
        <>
          <Button
            component={Link}
            to={`/reconciliation_report/${record.reconciliation_report_id}/show`}
            onClick={(e) => {e.stopPropagation()}}
            children={<FactCheckIcon />}
          />
          <ReportStateChipField source="reconciliation_report_state" />
        </>
    )
  );
}

const DiagnosisHccField = ({model, ...rest}) => {
  const record = useRecordContext();
  const dataProvider = useDataProvider();
  const [childContent, setChildContent] = useState(null);
  const labelText = `HCC ${model}`

  if (record.diagnosis_id === null) return null;

  if (childContent === null) {
    if (record.patient_id === null) {
      setChildContent('No patient');
    } else {
      dataProvider.getList(
        'diagnosis_condition',
        {
          filter: {
            id: record.diagnosis_id,
            model: model
          }
        }
      ).then((response) => {
        if (response.total === 0) {
          setChildContent('No HCC');
        } else {
          setChildContent(response.data.map(dc => dc.condition_id).join(', '));
        }
      }).catch((error) => {
        console.log('Error while getting HCC', error);
      });
    }
  }
  return childContent === null
    ? <LinearProgress sx={{ width: '25px', marginTop:'12px' }}/>
    : <TextField source="content" record={{content: `${childContent}`}} label={labelText} />;
}

const DiagnosisAcceptedYearField = ({conditionYear, model, ...rest}) => {
  const record = useRecordContext();
  const dataProvider = useDataProvider();
  const [childContent, setChildContent] = useState(null);
  const labelText = `Accepted on ${conditionYear}/${model}`

  if (record.diagnosis_id === null) return null;

  if (childContent === null) {
    if (record.patient_id === null) {
      setChildContent('No patient');
    } else {
      dataProvider.getList(
        'diagnosis_condition_related',
        {
          filter: {
            diagnosis_id: record.diagnosis_id,
            model: model
          }
        }
      ).then((response) => {
        if (response.total === 0) {
          setChildContent('No HCC');
        } else {
          let diagnoses = response.data.map((item) => item.related_diagnosis_id)
          dataProvider.getList(
            'patient_diagnosis_year',
            {
              filter: {
                patient_id: record.patient_id,
                'diag_code@_in': diagnoses,
                condition_year_id: conditionYear,
                mao_flag_int: 40
              }
            }
          ).then((response) => {
            if (response) {
              setChildContent(response.total > 0 ? 'Yes' : 'No');
            }
          });
        }
      }).catch((error) => {
        console.log('Error while getting accepted status', error);
      });
    }
  }
  return childContent === null
    ? <LinearProgress sx={{ width: '25px', marginTop:'12px' }}/>
    : <TextField source="content" record={{content: `${childContent}`}}/>;
}

const AppointmentShowBilledOmissions = () => (
  <ReferenceManyField
    label="Appointment billing omission"
    reference='appointment_billing_omission'
    target='appointment_id'
    sort={{field: 'diagnosis_id', order: 'ASC'}}
    perPage={VITE_DEFAULT_LISTSHOW_PER_PAGE}
    pagination={<Pagination />}
  >
    <Datagrid bulkActionButtons={false} rowClick={false}>
      <ReferenceField reference="diagnosis" source="diagnosis_id" label="Diagnosis">
        <FunctionField render={
          (diagnosis) => diagnosis?.id ?
            `[${diagnosis.id}] ${diagnosis.description}` :
            ''
        }/>
      </ReferenceField>
      <DiagnosisHccField model='v24' label="HCC v24" />
      <DiagnosisHccField model='v28' label="HCC v28" />
      <DiagnosisAcceptedYearField conditionYear={270} model='v24' label="Accepted on 270/v24" />
      <DiagnosisAcceptedYearField conditionYear={275} model='v24' label="Accepted on 275/v24" />
      <DiagnosisAcceptedYearField conditionYear={270} model='v28' label="Accepted on 270/v28" />
      <DiagnosisAcceptedYearField conditionYear={275} model='v28' label="Accepted on 275/v28" />
    </Datagrid>
  </ReferenceManyField>
);

const AppointmentShowHistory = () => (
  <ReferenceManyField
    label="History"
    reference="appointment_log"
    source="id"
    target="appointment_id"
    sort={{field: "created_at", order: "DESC"}}
    perPage={VITE_DEFAULT_LISTSHOW_PER_PAGE}
    pagination={<Pagination />}
  >
    <Datagrid rowClick={false} bulkActionButtons={false}>
      <DateField label="Date" source="created_at" />
      <TextField label="Loaded Type" source="appointment_type" />
      <TextField label="Inqora Type" source="type" />
      <TextField label="Loaded Status" source="appointment_status" />
      <TextField label="Inqora Status" source="status" />
      <TextField label="Billed Status" source="billed_status" />
      <TextField label="Custom Note" source="custom_note" />
      <ReferenceField label="Assigned To" reference="user" source="assigned_to" link={false}>
        <TextField source="name" />
      </ReferenceField>
      <BooleanField label="Archived" source="archived" />
      <BooleanField label="Reconciled" source="is_billing_reconciled" />
      <BooleanField label="Warning" source="warning" />
      <PatientNameReferenceField label="Member name" />
      <ReferenceField label="Provider" reference="provider_practitioner" source="provider_practitioner_id" link="show">
        <TextField source="name" />
      </ReferenceField>
      <ReferenceField label="Report" reference="reconciliation_report" source="reconciliation_report_id" link="show">
        <FunctionField render={(record) => record.next_appointment_date}/>
      </ReferenceField>
      {/*<BooleanField label="" source="did_not_match_insurance" />*/}
      <ReferenceField label="Event by" reference="user" source="user_id" link={false}>
        <TextField source="name" />
      </ReferenceField>
      <TextField source="message" />
    </Datagrid>
  </ReferenceManyField>
);

const AppointmentShow = () => (
  <Show>
    <SimpleShowLayout>
      <EnrichedAppointmentDateField source='date' label="Appointment date" />
      <DateField source="created_at" showTime={true} label="Appointment created in system date" />
      <OfficeReferenceField label="Office" />
      <TextField source="provider_name" label="Provider name" />
      <AppointmentProvider label="Provider" />
      <TextField source="insurance" label="Insurance"/>
      <TextField source="insurance_id" label="Insurance ID"/>
      <TextField source="patient_name" label="Name"/>
      <DateField source="patient_dob" label="Date of Birth"/>
      <AppointmentPatient label="Member name" />
      <TextField source="appointment_type" label="Loaded Type"/>
      <ReferenceField source="type" reference="appointment_type">
        <TextField source="description" />
      </ReferenceField>
      <TextField source="appointment_status" label="Loaded Status"/>
      <ReferenceField source="status" reference="appointment_status">
        <TextField source="description" />
      </ReferenceField>
      <BooleanField source="warning" />
      <TextField source="notes" label="Loaded note" />
      <BooleanField source="archived" />
      <ReferenceField source="billed_status" reference="appointment_billed_status">
        <TextField source="description" />
      </ReferenceField>
      <TextField source="custom_note"/>
      <AppointmentReport label="Report" />
      <ReferenceField reference="user" source="assigned_to" link={false}>
        <TextField source="name" />
      </ReferenceField>
      <AppointmentShowBilledOmissions label="Appointment billing omission" />
      <AppointmentShowHistory label="History" />
    </SimpleShowLayout>
  </Show>
);

export default AppointmentShow;
