import {
  BooleanField,
  Datagrid,
  DateField,
  FunctionField,
  Link,
  NumberField,
  Pagination,
  ReferenceField,
  ReferenceManyField,
  Show,
  SimpleShowLayout,
  TextField, TopToolbar,
  useRecordContext,
  useResourceContext,
  useDataProvider,
  useNotify,
  fetchRelatedRecords,
  downloadCSV,
  Button,
} from "react-admin";

import {Breadcrumbs, Typography, CardContent} from "@mui/material"
import AuditStatusChipField from "./AuditStatusChipField";
import {SapiensFieldsRow} from "../components/SapiensFieldsRow";
import AuditClaimStatusField from "./AuditClaimStatusField";
import {DiagnosisResultField} from "./components";
import DownloadIcon from '@mui/icons-material/GetApp';
import {useCallback} from "react";
import jsonExport from 'jsonexport/dist';
import {VITE_DEFAULT_LISTSHOW_PER_PAGE} from "../config";


const CustomBreadcrumb = (props) => {
  const record = useRecordContext();
  if (record === undefined) return null;
  return (
    <Breadcrumbs aria-label="breadcrumb" sx={{paddingTop: 1, paddingLeft: 2}}>
      <Link to="/audit">
        Audits
      </Link>
      <Typography>
        {`Audit ${record.id}`}
      </Typography>
    </Breadcrumbs>
  );
};

const CustomEmptyComponent = (props) => {
  return (
    <CardContent>
      <Typography variant='body2'>
        No diagnoses where validated in this audit
      </Typography>
    </CardContent>
  );
}

const exporter = async (results, fetchRelated, dataProvider, resource) => {
  if (!results) return;

  const resultsForExport = results.map(result => {
    // by destructuring the record, we can exclude fields from the export. In this case 'id'
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const {id, ...resultForExport } = result;
    return resultForExport;
  });

  jsonExport(resultsForExport, (err, csv) => {
    downloadCSV(csv, `audit_${results[0].audit_id}_results`);
  });
};

const CustomExportButton = (props) => {

  const defaultIcon = <DownloadIcon />;

  const {
    onClick,
    label = 'Export',
    icon = defaultIcon,
    exporter: customExporter,
    meta,
    ...rest
  } = props;
  const record = useRecordContext(props);
  const maxResults = 10000000;
  const exporter = customExporter;
  const resource = useResourceContext(props);
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const handleClick = useCallback(
    event => {
      dataProvider
        .getList(resource, {
          filter: { audit_id: record.id },
          sort: { field: 'id', order: 'ASC' },
          pagination: { page: 1, perPage: maxResults },
          meta,
        })
        .then(
          ({ data }) =>
            exporter &&
            exporter(
              data,
              fetchRelatedRecords(dataProvider),
              dataProvider,
              resource
            )
        )
        .catch(error => {
          console.error(error);
          notify('ra.notification.http_error', { type: 'error' });
        });
      if (typeof onClick === 'function') {
        onClick(event);
      }
    },
    [
      dataProvider,
      exporter,
      maxResults,
      notify,
      onClick,
      resource,
      meta,
    ]
  );

  return (
    <Button
      onClick={handleClick}
      label={label}
    >
      {icon}
    </Button>
  );
};

export const AuditResultsShowLayout = (props) => {
  const record = useRecordContext(props);
  const summaryFieldWidth = '60px'
  if (!record) return null;
  if (record.status !== 'finished') return <Typography>This view is only available for finished audits</Typography>;
  return (
    <>
      <CustomBreadcrumb/>
      <SimpleShowLayout>
        <SapiensFieldsRow>
          <ReferenceField reference='gatekeeper' source='gatekeeper_id' link={false}>
            <TextField source='name'/>
          </ReferenceField>
          <DateField source="date_from"/>
          <DateField source="date_to"/>
          <NumberField source="percentage" />
          <BooleanField source="hcc_only" />
          <TextField source="scope" />
          <ReferenceField label='Auditor' reference='user' source='user_id' link={false}>
            <TextField source='name'/>
          </ReferenceField>
        </SapiensFieldsRow>
        <AuditStatusChipField/>
        <ReferenceField reference='audit_results_summary' source='id' target='audit_id' label='Summary'>
          <>
            <SapiensFieldsRow>
              <FunctionField sx={{width: summaryFieldWidth}} render={(record) => `${(record.total_valid_claim_diagnoses * 100/record.total_audited_claim_diagnoses).toFixed(0)}%`} label='Accuracy' />
            </SapiensFieldsRow>
            <SapiensFieldsRow sx={{marginTop: '10px'}}>
              <TextField sx={{width: summaryFieldWidth}} source='total_claims' label={<div>Total<br/>claims</div>} />
              <TextField sx={{width: summaryFieldWidth}} source='audited_claims' label={<div>Audited<br/>claims</div>} />
              <TextField sx={{width: summaryFieldWidth}} source='total_audited_claim_diagnoses' label={<div>Total audited<br/>diagnoses</div>} />
              <TextField sx={{width: summaryFieldWidth}} source='total_valid_claim_diagnoses' label={<div>Total<br/>valid</div>} />
              <TextField sx={{width: summaryFieldWidth}} source='total_invalid_claim_diagnoses' label={<div>Total<br/>invalid</div>} />
            </SapiensFieldsRow>
            <SapiensFieldsRow sx={{marginTop: '10px'}}>
              <TextField sx={{width: summaryFieldWidth}} source='total_invalid_s_claim_diagnoses' label={<div>Total<br/>Invalid S</div>} />
              <TextField sx={{width: summaryFieldWidth}} source='total_invalid_ns_claim_diagnoses' label={<div>Total<br/>Invalid NS</div>} />
              <TextField sx={{width: summaryFieldWidth}} source='total_sencond_level_claim_diagnoses' label={<div>Total<br/>Second level</div>} />
              <TextField sx={{width: summaryFieldWidth}} source='total_missing_claim_diagnoses' label={<div>Total<br/>Missing</div>} />
            </SapiensFieldsRow>
          </>
        </ReferenceField>
        <ReferenceManyField
          label="Detail"
          reference="audit_results_detail"
          source="id"
          target="audit_id"
          sort={{field: 'id', order: 'ASC'}}
          perPage={VITE_DEFAULT_LISTSHOW_PER_PAGE}
          pagination={<Pagination />}>
          <TopToolbar>
            <CustomExportButton exporter={exporter}/>
          </TopToolbar>
          <Datagrid rowClick={false} bulkActionButtons={false} empty={<CustomEmptyComponent />}>
            <ReferenceField reference='claim' source='claim_id' link='show'/>
            <TextField source='diagnosis_id'/>
            <ReferenceField reference='diagnosis' source='diagnosis_id'/>
            <AuditClaimStatusField />
            <DiagnosisResultField label='Result' />
            <TextField source='comment'/>
          </Datagrid>
        </ReferenceManyField>
      </SimpleShowLayout>
    </>
  );
}

export const AuditResultsShow = (props) => {
  return (
    <Show actions={false}>
      <AuditResultsShowLayout/>
    </Show>
  );
}

export default AuditResultsShow;
