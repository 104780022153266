import {
  SelectInput,
  ReferenceInput,
  ReferenceArrayInput,
  AutocompleteInput,
  AutocompleteArrayInput,
  Labeled,
  FunctionField,
  ReferenceField,
  ReferenceArrayField,
  TextField,
  SingleFieldList,
  ChipField,
  useRecordContext,
  useDataProvider,
  required,
} from "react-admin";
import {useState} from "react";
import {useWatch, useFormContext} from "react-hook-form"
import {Stack} from "@mui/material";
import DiagnosisInput from "../claim/DiagnosisInput";

const RuleConditionValuesList = () => {
  const record = useRecordContext();
  const dataProvider = useDataProvider();
  const [chips, setChips] = useState(null);

  const getValueDesc = async (classValue, value) => {
    if (classValue?.choices !== undefined) {
      const valueRef = classValue.choices.filter(item => item['id'] === value)[0];
      return Promise.resolve(`${classValue.name}: ${valueRef['name']}`);
    }
    else if (classValue?.reference_desc !== undefined) {
      return dataProvider.
              getOne(classValue.reference, {id: value}).
              then((response) => {
                const item = response['data'];
                if (classValue.reference_desc_id) {
                  return `${classValue.name}: [${item.id}] ${item[classValue.reference_desc]}`;
                }
                else {
                  return `${classValue.name}: ${item[classValue.reference_desc]}`;
                }
              });
    }
    else {
      return Promise.resolve(`${classValue.name}: ${value}`);
    }
  };

  const addConditionReqs = (conditionReqs, classValue, recValue) => {
    const valueId = classValue['id'];
    if (recValue[valueId]) {
      if (classValue.value_type === "array") {
        for (const valIndex in recValue[valueId]) {
          conditionReqs.push(
            getValueDesc(classValue, recValue[valueId][valIndex])
          );
        }
      }
      else {
        conditionReqs.push(
          getValueDesc(classValue, recValue[valueId])
        );
      }
    }
  };

  if (record && record?.class_id && chips === null) {
    dataProvider.
      getOne('rule_condition_class', {id: record.class_id}).
      then((response) => {
        const conditionClass = response['data'];
        const classValues = conditionClass['definition']['values'];
        const conditionReqs = [];
        for (const index in classValues) {
          addConditionReqs(conditionReqs, classValues[index], record.value);
        }
        Promise.
          all(conditionReqs).
          then((conditionValues) => conditionValues.map((value, idx) => (
            <ChipField
              key={`${idx}-${value}`}
              record={{ name: value }}
              source="name"
              size="small"
              sx={{ margin: "5px" }}
            />
          ))).
          then(setChips);
      });
  }

  return (
    <Stack
      direction="row"
      useflexgap="true"
      sx={{ flexWrap: "wrap" }}
    >
      {chips}
    </Stack>
  );
};

const RuleConditionComponentSelect = (mode, classValue) => {
  return (
    mode === "edit" ?
      <SelectInput
        source={`value[${classValue.id}]`}
        choices={classValue.choices ? classValue.choices : null}
        label={classValue.name}
        validate={classValue.required ? required() : null}
      /> :
      <Labeled label={classValue.name}>
        <FunctionField
          render={
            record => `${classValue.choices.filter(item => item['id'] === record.value[classValue.id])[0]['name']}`
          }
        />
      </Labeled>
  );
};

const RuleConditionComponentReference = (mode, classValue) => {
  return (
    mode === "edit" ?
      <ReferenceInput
        source={`value[${classValue.id}]`}
        reference={classValue.reference}
        label={classValue.name}
        sort={{ field: classValue.reference_desc, order: "ASC" }}
      >
        {RuleConditionComponent(mode, classValue.field_subtype, classValue)}
      </ReferenceInput> :
      <Labeled label={classValue.name}>
        <ReferenceField
          source={`value[${classValue.id}]`}
          reference={classValue.reference}
        >
          {RuleConditionComponent(mode, classValue.field_subtype, classValue)}
        </ReferenceField>
      </Labeled>
  );
};

const RuleConditionComponentReferenceArray = (mode, classValue) => {
  return (
    mode === "edit" ?
      <ReferenceArrayInput
        source={`value[${classValue.id}]`}
        reference={classValue.reference}
        label={classValue.name}
        sort={{ field: classValue.reference_desc, order: "ASC" }}
        validate={classValue.required ? required() : null}
      >
        {RuleConditionComponent(mode, classValue.field_subtype, classValue)}
      </ReferenceArrayInput> :
      <Labeled label={classValue.name}>
        <ReferenceArrayField
          source={`value[${classValue.id}]`}
          reference={classValue.reference}
        >
          {RuleConditionComponent(mode, classValue.field_subtype, classValue)}
        </ReferenceArrayField>
      </Labeled>
  );
};

const RuleConditionComponentAutocomplete = (mode, classValue) => {
  return (
    mode === "edit" ?
      <AutocompleteInput
        optionText={(item) => classValue.reference_desc_id && item?.id ?
          `[${item.id}] ${item[classValue.reference_desc]}` :
          `${item[classValue.reference_desc]}`
        }
        filterToQuery={(searchText) => ({ [`${classValue.reference_desc}@_ilike`]: searchText })}
        label={classValue.name}
        validate={classValue.required ? required() : null}
      /> :
      (
        classValue.reference_desc_id ?
        <FunctionField render={record => `[${record.id}] ${record[classValue.reference_desc]}`}/> :
        <TextField source={classValue.reference_desc} />
      )
  );
};

const RuleConditionComponentAutocompleteArray = (mode, classValue) => {
  return (
    mode === "edit" ?
      <AutocompleteArrayInput
        optionText={(item) => classValue.reference_desc_id && item?.id ?
          `[${item.id}] ${item[classValue.reference_desc]}` :
          `${item[classValue.reference_desc]}`
        }
        filterToQuery={(searchText) => ({ [`${classValue.reference_desc}@_ilike`]: searchText })}
        label={classValue.name}
        validate={classValue.required ? required() : null}
      /> : 
      <SingleFieldList linkType={false}>
        {
          classValue.reference_desc_id ?
          <FunctionField render={(record) => (
            <ChipField record={{ name: `[${record.id}] ${record[classValue.reference_desc]}` }} source="name" size="small" />
          )} /> :
          <ChipField source={classValue.reference_desc} />
        }
      </SingleFieldList>
  );
};


const RuleConditionComponentDiagnosis = (mode, classValue) => {
  return (
    mode === "edit" ?
      <DiagnosisInput
        source={`value[${classValue.id}]`}
        reference={classValue.reference}
        label={classValue.name}
        validate={classValue.required ? required() : null}
      /> :
      <Labeled label={classValue.name}>
        <ReferenceField
          source={`value[${classValue.id}]`}
          reference={classValue.reference}
        >
          <FunctionField render={record => `[${record.id}] ${record.description}`}/>
        </ReferenceField>
      </Labeled>
  );
};

const RuleConditionComponent = (mode, field_type, classValue) => {
  if (field_type === 'Select') {
    return RuleConditionComponentSelect(mode, classValue);
  }
  else if (field_type === 'Reference') {
    return RuleConditionComponentReference(mode, classValue);
  }
  else if (field_type === 'ReferenceArray') {
    return RuleConditionComponentReferenceArray(mode, classValue);
  }
  else if (field_type === 'Autocomplete') {
    return RuleConditionComponentAutocomplete(mode, classValue);
  }
  else if (field_type === 'AutocompleteArray') {
    return RuleConditionComponentAutocompleteArray(mode, classValue);
  }
  else if (field_type === 'Diagnosis') {
    return RuleConditionComponentDiagnosis(mode, classValue);
  }
  else {
    return null;
  }
};

const RuleConditionValuesGeneral = ({mode, ...rest}) => {
  const record = useRecordContext();
  const dataProvider = useDataProvider();
  const { setValue } = useFormContext();
  const [controls, setControls] = useState(null);
  const [lastClassId, setLastClassId] = useState(record?.class_id);
  const classIdWatch = (mode === "edit") ? useWatch({ name: 'class_id' }) : null;
  const classId = record?.class_id || classIdWatch;

  if (classId != lastClassId){
    setControls(null);
    setLastClassId(classId);
    setValue("value", null);
  }

  if (classId && controls === null) {
    dataProvider.
      getOne('rule_condition_class', {id: classId}).
      then((response) => {
        const conditionClass = response['data'];
        const classValues = conditionClass['definition']['values'];
        const conditionControls = [];
        for (const index in classValues) {
          conditionControls.push(
            RuleConditionComponent(
              mode,
              classValues[index].field_type,
              classValues[index]
            )
          );
        }
        setControls(conditionControls);
      });
  }
  return (
    mode === "edit" ?
    <>
      {controls}
    </> :
    <Stack spacing={1}>
      {controls}
    </Stack>
  );
};

const RuleConditionValues = ({mode, ...rest}) => (
  mode === "list" ?
  <RuleConditionValuesList /> :
  <RuleConditionValuesGeneral mode={mode} {...rest} />
);

export default RuleConditionValues;
