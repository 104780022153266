import RuleIcon from '@mui/icons-material/Rule';
import RuleActionList from "./RuleActionList";
import RuleActionCreate from "./RuleActionCreate";
import RuleActionEdit from "./RuleActionEdit";
import RuleActionShow from "./RuleActionShow";

const resource = {
  list: RuleActionList,
  create: RuleActionCreate,
  edit: RuleActionEdit,
  show: RuleActionShow,
  icon: RuleIcon,
};

export default resource;
