import RuleIcon from '@mui/icons-material/Rule';
import RuleList from "./RuleList";
import RuleCreate from "./RuleCreate";
import RuleEdit from "./RuleEdit";
import RuleShow from "./RuleShow";

const resource = {
  list: RuleList,
  create: RuleCreate,
  edit: RuleEdit,
  show: RuleShow,
  icon: RuleIcon,
};

export default resource;
