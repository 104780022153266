import {
  Create,
  SimpleForm,
  ReferenceInput,
  AutocompleteInput,
  required,
} from "react-admin";
import {useLocation} from "react-router-dom";
import RuleActionValues from "./RuleActionValues";

const RuleActionCreate = () => {
  let { state } = useLocation();

  const redirectFx = (resource, id, data) => {
    return `rule/${data.rule_id}`;
  }

  const transformFx = (data) => {
    return {
      ...data,
      rule_id: state?.rule_id
    };
  }

  return (
    <Create redirect={redirectFx} transform={transformFx}>
      <SimpleForm>
        <ReferenceInput
          source="type"
          reference="rule_action_type"
          sort={{ field: "description", order: "ASC" }}
        >
          <AutocompleteInput
            optionText="description"
            validate={required()}
            filterToQuery={(searchText) => ({ "description@_ilike": searchText })}
          />
        </ReferenceInput>
        <RuleActionValues mode="edit" />
      </SimpleForm>
    </Create>
  );
};

export default RuleActionCreate;
