import {
  Show,
  SimpleShowLayout,
  TextField,
  DateField,
  ReferenceField,
  ReferenceManyField,
  List,
  Datagrid,
  ReferenceArrayInput,
  SelectArrayInput,
  ReferenceInput,
  SelectInput,
  Pagination,
  EditButton,
  useRecordContext,
} from 'react-admin';
import { BulkUpdateGapDispositionButton } from './GapPatientButtons';
import DiagnosisInput from '../claim/DiagnosisInput';
import PatientNameReferenceField from '../patient/PatientNameReferenceField';
import GatekeeperReferenceField from '../gatekeeper/GatekeeperReferenceField';
import {VITE_DEFAULT_LISTSHOW_PER_PAGE} from "../config";

const gapPatientShowFilters = [
  <ReferenceArrayInput
    label='Status'
    source='status'
    reference='gap_status'
    sort={{ field: 'description', order: 'ASC' }}
    alwaysOn
  >
    <SelectArrayInput label='Status' optionText='description' />
  </ReferenceArrayInput>,
  <DiagnosisInput source='diag_code' reference='diagnosis' label='Diagnosis'/>,
  <ReferenceArrayInput
    label='Disposition'
    source='working_status'
    reference='gap_working_status'
    sort={{ field: 'description', order: 'ASC' }}
    alwaysOn
  >
    <SelectArrayInput label='Disposition' optionText='description' />
  </ReferenceArrayInput>,
];

const GapPatientShowBulkActionButtons = props => (
  <>
    <BulkUpdateGapDispositionButton {...props} />
  </>
);

export const GapPatientShowGapsList = () => {
  const record = useRecordContext();

  return (
    <ReferenceManyField
      label='Gaps'
      reference='gap'
      source='id'
      target='patient_id'
    >
      <List
        filter={{ patient_id: record.id }}
        filters={gapPatientShowFilters}
        exporter={false}
        perPage={VITE_DEFAULT_LISTSHOW_PER_PAGE}
        pagination={<Pagination />}
      >
        <Datagrid rowClick='show' bulkActionButtons={<GapPatientShowBulkActionButtons />}>
          <ReferenceField
            reference='gap_status'
            source='status'
            label='Type'
            link={false}
          >
            <TextField source='description' />
          </ReferenceField>
          <ReferenceField
            reference='claim'
            source='latest_claim_id'
            label='Date'
            link='show'
          >
            <DateField source='min_service_date' />
          </ReferenceField>
          <ReferenceField
            reference='claim'
            source='source_claim_id'
            label='Source'
            link='show'
          >
            <TextField source='claim_nbr' />
          </ReferenceField>
          <TextField source='diag_code' label='Diagnosis' />
          <ReferenceField
            label='HCC v24'
            source='diag_code'
            reference='diagnosis_conditions_agg'
          >
            <TextField source='conditions_v24'/>
          </ReferenceField>
          <ReferenceField
            label='HCC v28'
            source='diag_code'
            reference='diagnosis_conditions_agg'
          >
            <TextField source='conditions_v28'/>
          </ReferenceField>
          <ReferenceField
            reference='diagnosis'
            source='diag_code'
            label='Diagnosis Description'
            link={false}
          >
            <TextField source='description' />
          </ReferenceField>
          <ReferenceField
            reference='gap_working_status'
            source='working_status'
            label='Disposition'
            link={false}
          >
            <TextField source='description' />
          </ReferenceField>
          <EditButton />
        </Datagrid>
      </List>
    </ReferenceManyField>
  );
};

export const GapPatientShow = () => (
  <Show>
    <SimpleShowLayout>
      <PatientNameReferenceField source='id' label='Member Name' />
      <GatekeeperReferenceField source='patient.current_eligibility.gatekeeper_id' label='PCP' />
      <TextField source='patient.current_eligibility.id_value' label='Member ID' />
      <TextField source='patient.date_of_birth' label='DOB' />
      <GapPatientShowGapsList />
    </SimpleShowLayout>
  </Show>
);

export default GapPatientShow;
