import {
  Datagrid,
  List,
  TextField,
  SearchInput
} from 'react-admin';
import GatekeeperReferenceField from "../gatekeeper/GatekeeperReferenceField";
import {VITE_DEFAULT_LIST_PER_PAGE} from "../config";

const providerPractitionerFilters = [
  <SearchInput source="name" alwaysOn/>
];

const ProviderPractitionerList = () => (
  <List
    exporter={false}
    filters={providerPractitionerFilters}
    perPage={VITE_DEFAULT_LIST_PER_PAGE}
  >
    <Datagrid rowClick="edit">
      <TextField source="name" />
      <TextField source="email" />
      <GatekeeperReferenceField />
    </Datagrid>
  </List>
);

export default ProviderPractitionerList;
