import {
  List,
  Datagrid,
  TextField,
  DateField,
  SelectInput,
  TextInput,
  DateInput,
  useRecordContext,
  ReferenceField,
  ReferenceArrayInput,
  AutocompleteArrayInput,
} from 'react-admin';
import CheckIcon from '@mui/icons-material/Check';
import ErrorIcon from '@mui/icons-material/Error';
import NewReleasesIcon from '@mui/icons-material/NewReleases';
import WarningIcon from '@mui/icons-material/Warning';
import {Tooltip} from "@mui/material";
import {VITE_DEFAULT_LIST_PER_PAGE} from "../config";

const insuranceFileFilters = [
  <ReferenceArrayInput source="insurance_provider_id" reference="insurance_provider" alwaysOn>
    <AutocompleteArrayInput
      optionText="description"
      filterToQuery={(searchText) => ({ "description@_ilike": searchText })}
    />
  </ReferenceArrayInput>,
  <ReferenceArrayInput source="grouper" reference="file_grouper" alwaysOn>
    <AutocompleteArrayInput
      optionText="description"
      filterToQuery={(searchText) => ({ "description@_ilike": searchText })}
    />
  </ReferenceArrayInput>,
  <ReferenceArrayInput source="file_class" reference="file_class" alwaysOn>
    <AutocompleteArrayInput
      optionText="id"
      filterToQuery={(searchText) => ({ "id@_ilike": searchText })}
    />
  </ReferenceArrayInput>,
  <SelectInput label="Status" source="status" alwaysOn choices={[
    { id: "new", name: "New" },
    { id: "failed", name: "Failed" },
    { id: "incorporated", name: "Processed" },
    { id: "missing", name: "Missing" },
  ]}/>,
  <DateInput label="Date from" source="pe_date@_gte" alwaysOn/>,
  <DateInput label="Date to" source="pe_date@_lte" alwaysOn/>,
  <ReferenceArrayInput source="file_prefix" reference="insurance_file_prefix">
    <AutocompleteArrayInput
      optionText="id"
      filterToQuery={(searchText) => ({ "id@_ilike": searchText })}
    />
  </ReferenceArrayInput>,
  <TextInput label="Aws key" source="aws_key"/>,
];


const StatusComponent = (props) => {
  const record = useRecordContext();
  if (!record) return null;
  switch (record.status) {
    case 'incorporated':
      return <Tooltip title={'Processed'}>
               <CheckIcon color='success' fontSize='small'/>
             </Tooltip>;
    case 'failed':
      return <Tooltip title={'Failed'}>
               <ErrorIcon color='error' fontSize='small'/>
             </Tooltip>;
    case 'new':
      return <Tooltip title={'New'}>
               <NewReleasesIcon color='info' fontSize='small'/>
             </Tooltip>;
    case 'missing':
      return <Tooltip title={'Missing'}>
               <WarningIcon color='warning' fontSize='small'/>
             </Tooltip>;
    default:
      return <p>{record.status}</p>;
  }
};


const InsuranceFileList = () => {
  return(
    <List
      exporter={false}
      perPage={VITE_DEFAULT_LIST_PER_PAGE}
      sort={{field: 'pe_date', order: 'DESC'}}
      filter={{'status@_neq': 'ignored'}}
      filters={insuranceFileFilters}
    >
      <Datagrid bulkActionButtons={false}>
        <ReferenceField source="insurance_provider_id" reference="insurance_provider">
          <TextField source="description" />
        </ReferenceField>
        <TextField source='grouper' />
        <TextField label="Class" source="file_class" />
        <DateField label='Period' source="pe_date" />
        <StatusComponent label='Status'/>
        <TextField label="Prefix" source="file_prefix" />
        <TextField label='File name' source="aws_key" />
        <TextField source="error_message" />
      </Datagrid>
    </List>
  );
}

export default InsuranceFileList;
