import {
  SearchInput,
  List,
  Datagrid,
  TextField,
  ReferenceField} from "react-admin";
import QueryKindInput from "./QueryKindInput";
import {VITE_DEFAULT_LIST_PER_PAGE} from "../config";

const queryFilters = [
  <SearchInput source="description" alwaysOn/>,
  <QueryKindInput source="kind" alwaysOn/>,
];

const QueryList = () => {
  return(
    <List perPage={VITE_DEFAULT_LIST_PER_PAGE} filters={queryFilters} exporter={false}>
      <Datagrid rowClick="edit">
        <TextField source="id" />
        <ReferenceField reference='query_kind' source='kind'>
          <TextField source="description" />
        </ReferenceField>
        <TextField source="description" />
      </Datagrid>
    </List>
  );
}

export default QueryList;
