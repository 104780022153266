import {
  DateField,
  Labeled,
  ReferenceField,
  ReferenceManyField,
  Show,
  SimpleShowLayout,
  TextField,
  useRecordContext,
  Datagrid,
  useUpdate,
  useGetOne,
  useNotify,
} from "react-admin";
import {
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Grid,
  Stack,
  TextField as MUITextField,
} from "@mui/material"
import {useState} from "react";
import PatientEligibilityListField from "../patient/PatientEligibilityListField";
import {ReconciliationReportAnswersToolbar} from "./ReportShowButtons";
import {canAnswer} from "./domain";
import ReconciliationReportTitle from "./ReconciliationReportTitle";


const ReportHeader = () => {
  return (
    <Grid container direction="row" spacing={2}>
      <Grid item>
        <ReferenceField reference='patient' source='patient_id' emptyText='Empty'>
          <Labeled label='Inqora ID'>
            <TextField source="id"/>
          </Labeled>
        </ReferenceField>
      </Grid>
      <Grid item>
        <ReferenceField reference='patient' source='patient_id' emptyText='Empty'>
          <Labeled label='Humana ID'>
            <TextField source="current_eligibility.id_value"/>
          </Labeled>
        </ReferenceField>
      </Grid>
      <Grid item>
        <ReferenceField reference='patient' source='patient_id' emptyText='Empty' link={false}>
          <Labeled label='Date of Birth'>
            <DateField source="date_of_birth"/>
          </Labeled>
        </ReferenceField>
      </Grid>
      <Grid item>
        <ReferenceField reference='patient' source='patient_id' emptyText='Empty' link={false}>
          <Labeled label='Primary care physician'>
            <ReferenceField source="current_eligibility.gatekeeper_id" reference="gatekeeper" emptyText='Empty'>
              <TextField source="name"/>
            </ReferenceField>
          </Labeled>
        </ReferenceField>
      </Grid>
      <Grid item>
        <ReferenceField reference='patient' source='patient_id' emptyText='Empty' link={false}>
          <Labeled label="Line of business">
            <ReferenceField label='Line of business' source="current_eligibility.lob_id" reference="line_of_business">
                <TextField source="description" />
              </ReferenceField>
          </Labeled>
        </ReferenceField>
      </Grid>
      <Grid item>
        <ReferenceField reference='patient' source='patient_id' emptyText='Empty' link={false}>
          <Labeled>
            <ReferenceManyField label="Eligibility dates" reference="patient_eligibility" target="patient_id">
              <PatientEligibilityListField/>
            </ReferenceManyField>
          </Labeled>
        </ReferenceField>
      </Grid>
      <Grid item>
        <Labeled>
          <TextField source='state'/>
        </Labeled>
      </Grid>
    </Grid>
  )
}


const DiagnosisAnswerRadioGroup = (props) => {
  const notify = useNotify();
  const record = useRecordContext();
  const [update] = useUpdate();
  if (!record) return null;
  const isDisabled = props.disabled;
  const [value, setValue] = useState(record.relevance_id);

  const resource = record.item_type === 'hcc' ?
    'reconciliation_report_query_condition' :
    'reconciliation_report_query_diagnosis';

  const {data: rrqItem} = useGetOne(resource, {id: record.ref_id});

  const handleChange = (event) => {
    if (rrqItem !== undefined) {
      update(
        resource,
        {
          id: rrqItem.id,
          data: {'relevance_id': event.target.value},
          previousData: rrqItem
        }
      ).then(() => {
        notify(`Answer saved`, { type: 'success' });
        setValue(event.target.value);
      }).catch((error) => {
        notify(`Error: ${error.message}`, { type: 'error' });
      });
    }
  };

  return <FormControl
    margin='none'
    sx={{
      '& .MuiSvgIcon-root': {
        fontSize: '0.875rem',
      },
      '& .MuiFormControlLabel-label': {
        fontSize: '0.875rem',
      }
    }}
  >
    <RadioGroup
      aria-labelledby="demo-controlled-radio-buttons-group"
      name="controlled-radio-buttons-group"
      value={value}
      onChange={handleChange}
      row
    >
      <FormControlLabel value="a" control={<Radio/>} label="Accepted" disabled={isDisabled}/>
      <FormControlLabel value="an" control={<Radio/>} label="Accepted (not documented)" disabled={isDisabled}/>
      <FormControlLabel value="d" control={<Radio/>} label="Denied" disabled={isDisabled}/>
      <FormControlLabel value="nr" control={<Radio/>} label="Not relevant" disabled={isDisabled}/>
      <FormControlLabel value="na" control={<Radio/>} label="Not answered" disabled={isDisabled}/>
    </RadioGroup>
  </FormControl>
}


const DiagnosisNote = (props) => {
  const notify = useNotify();
  const record = useRecordContext();
  const [update] = useUpdate();
  if (!record) return null;
  const isDisabled = props.disabled;

  return (
    <MUITextField
      onBlur={(event) => {
        update(
          'reconciliation_report_query_diagnosis',
          { id: record.id, data: {'notes': event.target.value}, previousData: record }
        ).then(() => {
          notify(`Note saved`, { type: 'success' });
        }).catch((error) => {
          notify(`Error: ${error.message}`, { type: 'error' });
        })
      }}
      label='Notes'
      disabled={isDisabled}
      defaultValue={record.notes}
      fullWidth
      />
  )

}


const ReconciliationReportQueryDiagnosisEditInline = (props) => {
  const isDisabled = props.disabled;
  return (
    <>
      <Stack direction="row" alignItems="center" spacing={2}>
        <TextField source="item_id" variant="bold"/>
        <TextField source="item_description"/>
      </Stack>
      <Stack direction="row" alignItems="center" spacing={2}>
        <DiagnosisAnswerRadioGroup disabled={isDisabled}/>
      </Stack>
      <DiagnosisNote disabled={isDisabled}/>
    </>
  )
}


const ReportAnswersList = () => {
  const record = useRecordContext();
  if (!record) return null;
  const isDisabled = !canAnswer(record.state);

  return (
    <ReferenceManyField reference="reconciliation_report_query" target="report_id" >
      <Datagrid bulkActionButtons={false}>
        <ReferenceManyField reference="reconciliation_report_query_item" target="reconciliation_report_query_id">
          <Datagrid bulkActionButtons={false}>
            <ReconciliationReportQueryDiagnosisEditInline disabled={isDisabled}/>
          </Datagrid>
        </ReferenceManyField>
      </Datagrid>
    </ReferenceManyField>
  )
}

const ReconciliationReportAnswers = () => (
    <Show title={<ReconciliationReportTitle/>} actions={<ReconciliationReportAnswersToolbar />} >
      <SimpleShowLayout>
        <Grid container spacing={2}>
          <Grid item xs={12} md={9}>
            <ReportHeader/>
          </Grid>
        </Grid>

        <ReportAnswersList/>

      </SimpleShowLayout>
    </Show>
  );

export default ReconciliationReportAnswers;
