import {
  Edit,
  SimpleForm,
  TextInput,
  ReferenceManyField,
  Button,
  Link,
  Datagrid,
  TextField,
  ReferenceField,
  EditButton,
  Toolbar,
  SaveButton,
  DeleteButton,
  DeleteWithConfirmButton,
  Pagination,
  useRecordContext,
  useDataProvider,
  useListContext,
  useNotify,
  useRefresh,
  required,
} from "react-admin";
import {useState} from "react";
import Backdrop from "@mui/material/Backdrop";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import CircularProgress from "@mui/material/CircularProgress";
import RuleActionValues from "../ruleaction/RuleActionValues";
import RuleConditionValues from "../rulecondition/RuleConditionValues";
import {VITE_DEFAULT_LISTSHOW_PER_PAGE} from "../config";

const RuleEditToolbar = () => {
  const record = useRecordContext();

  return (
    <Toolbar>
      <SaveButton/>
      <DeleteWithConfirmButton
          confirmContent="You will not be able to recover this rule. Are you sure?"
          confirmColor="warning"
          translateOptions={{ name: record.name }}
          sx={{ right: 'calc(-89%)' }}
      />
    </Toolbar>
  );
};

const BulkDeleteRuleConditions = () => {
  const notify = useNotify();
  const refresh = useRefresh();
  const dataProvider = useDataProvider();
  const [running, setRunning] = useState(false);
  const {selectedIds, onUnselectItems} = useListContext();

  const delSelected = () => {
    setRunning(true);
    dataProvider.deleteMany("rule_condition", { ids: selectedIds })
      .then(() => {
        onUnselectItems();
        refresh();
      })
      .catch((e) => {
        notify("Error deleting conditions", { type: "error" });
        console.log("error when deleting conditions");
        console.log(e);
      })
      .finally(() => {
        setRunning(false);
      });
  }

  return (
    <>
      <Button
        label="Remove conditions"
        disabled={running}
        onClick={delSelected}
      >
        <DeleteIcon />
      </Button>
      <Backdrop
        sx={{ color: "#fff", zIndex:  1000 }}
        open={running}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
};

const RuleEditConditionsBulkActionButtons = props => (
  <>
    <BulkDeleteRuleConditions {...props} />
  </>
);

const RuleEditConditions = () => {
  const record = useRecordContext();

  return (
    <ReferenceManyField
      label="Conditions"
      reference="rule_condition"
      source="id"
      target="rule_id"
      perPage={VITE_DEFAULT_LISTSHOW_PER_PAGE}
      pagination={<Pagination />}
    >
      <Button
        startIcon={<AddIcon />}
        label="Add condition"
        component={Link}
        to='/rule_condition/create'
        state={{rule_id: record.id}}
        style={{width: 'fit-content'}}
      />
      <Datagrid
        rowClick={false}
        bulkActionButtons={<RuleEditConditionsBulkActionButtons/>}
      >
        <ReferenceField source="class_id" reference="rule_condition_class">
          <TextField source="name" />
        </ReferenceField>
        <RuleConditionValues mode="list" label="Values" />
        <EditButton />
      </Datagrid>
    </ReferenceManyField>
  );
};

const BulkDeleteRuleActions = () => {
  const notify = useNotify();
  const refresh = useRefresh();
  const dataProvider = useDataProvider();
  const [running, setRunning] = useState(false);
  const {selectedIds, onUnselectItems} = useListContext();

  const delSelected = () => {
    setRunning(true);
    dataProvider.deleteMany("rule_action", { ids: selectedIds })
      .then(() => {
        onUnselectItems();
        refresh();
      })
      .catch((e) => {
        notify("Error deleting actions", { type: "error" });
        console.log("error when deleting actions");
        console.log(e);
      })
      .finally(() => {
        setRunning(false);
      });
  }

  return (
    <>
      <Button
        label="Remove actions"
        disabled={running}
        onClick={delSelected}
      >
        <DeleteIcon />
      </Button>
      <Backdrop
        sx={{ color: "#fff", zIndex:  1000 }}
        open={running}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
};

const RuleEditActionsBulkActionButtons = props => (
  <>
    <BulkDeleteRuleActions {...props} />
  </>
);

const RuleEditActions = () => {
  const record = useRecordContext();

  return (
    <ReferenceManyField
      label="Actions"
      reference="rule_action"
      source="id"
      target="rule_id"
      perPage={VITE_DEFAULT_LISTSHOW_PER_PAGE}
      pagination={<Pagination />}
    >
      <Button
        startIcon={<AddIcon />}
        label="Add action"
        component={Link}
        to='/rule_action/create'
        state={{rule_id: record.id}}
        style={{width: 'fit-content'}}
      />
      <Datagrid
        rowClick={false}
        bulkActionButtons={<RuleEditActionsBulkActionButtons/>}
      >
        <ReferenceField source="type" reference="rule_action_type">
          <TextField source="description" />
        </ReferenceField>
        <RuleActionValues mode="list" label="Value" />
        <EditButton />
      </Datagrid>
    </ReferenceManyField>
  );
};

const RuleEdit = () => (
  <Edit>
    <SimpleForm toolbar={<RuleEditToolbar />}>
      <TextInput source="name" validate={required()} />
      <RuleEditConditions />
      <RuleEditActions />
    </SimpleForm>
  </Edit>
);

export default RuleEdit;
