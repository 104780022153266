import {
  DateInput,
  ReferenceInput,
  ReferenceArrayInput,
  SelectInput,
  SelectArrayInput,
  AutocompleteInput,
  Datagrid,
  ReferenceField,
  TextField,
  NullableBooleanInput,
  Button,
  TopToolbar,
  FilterButton,
  ExportButton,
  useRecordContext,
  useGetOne,
  downloadCSV,
  SimpleShowLayout,
  List,
} from 'react-admin';
import {useState} from 'react';
import {Link} from 'react-router-dom';
import jsonExport from 'jsonexport/dist';
import { Chip } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import FactCheckIcon from '@mui/icons-material/FactCheck';
import CheckIcon from '@mui/icons-material/Check';
import CircleIcon from '@mui/icons-material/Circle';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';

import {
  BulkArchiveAppointmentButton,
  BulkUnarchiveAppointmentButton,
  BulkReconcileAppointmentButton,
  BulkUndoReconcileAppointmentButton,
  BulkAppointmentAssignment
} from "./AppointmentButtons";

import PatientInput from '../patient/PatientInput';
import UserInput from '../user/UserInput';
import PatientNameReferenceField from '../patient/PatientNameReferenceField';
import OfficeInput from "../office/OfficeInput";
import ProviderPractitionerInput from '../providerpractitioner/ProviderPractitionerInput';
import ReportStateChipField from "../reconciliationreport/ReportStateChipField";
import { getUserId } from '../auth/sapiensUser';
import EnrichedAppointmentDateField from "./EnrichedAppointmentDateField";
import OfficeReferenceField from "../office/OfficeReferenceField";
import {VITE_DEFAULT_LIST_PER_PAGE} from "../config";


const QuickFilter = ({ label }) => {
  return <Chip sx={{ marginBottom: 1 }} label={label} />;
};

const appointmentFilters = (props) => {
  return [
    <NullableBooleanInput
      label="Archived status"
      source="archived"
      falseLabel="Unarchived"
      trueLabel="Archived"
      alwaysOn
    />,
    <DateInput label="Date from" source="date@_gte"/>,
    <DateInput label="Date to" source="date@_lte"/>,
    <ReferenceInput
      label="Patient insurance"
      source="patient#current_eligibility#insurance_provider_id@_eq"
      reference="insurance_provider"
      sort={{ field: "description", order: "ASC" }}
    >
      <AutocompleteInput
        label="Patient insurance"
        optionText="description"
        filterToQuery={(searchText) => ({ "description@_ilike": searchText })}
      />
    </ReferenceInput>,
    <ReferenceInput
      label="Type"
      source="type"
      reference="appointment_type"
      sort={{ field: "description", order: "ASC" }}
    >
      <AutocompleteInput
        optionText="description"
        filterToQuery={(searchText) => ({ "description@_ilike": searchText })}
      />
    </ReferenceInput>,
    <ReferenceInput
      label="Status"
      source="status"
      reference="appointment_status"
      sort={{ field: "description", order: "ASC" }}
    >
      <AutocompleteInput
        optionText="description"
        filterToQuery={(searchText) => ({ "description@_ilike": searchText })}
      />
    </ReferenceInput>,
    <NullableBooleanInput label="Warning" source="warning" />,
    <NullableBooleanInput
      label="Patient match"
      source="patient_id@_is_null"
      falseLabel="Has patient"
      trueLabel="Without patient"
    />,
    <PatientInput label="Patient" source="patient_id" name="patient_id"/>,
    <OfficeInput source="office_id" />,
    <ProviderPractitionerInput source="provider_practitioner_id" label="Provider" />,
    <ReferenceArrayInput
      label="Billed status"
      source="billed_status"
      reference="appointment_billed_status"
      sort={{ field: "description", order: "ASC" }}
    >
      <SelectArrayInput label="Billed status" optionText="description" />
    </ReferenceArrayInput>,
    <NullableBooleanInput label="Reconciled" source="is_billing_reconciled" />,
    <NullableBooleanInput
      label="Reconciliation report"
      source="reconciliation_report_id@_is_null"
      falseLabel="Has report"
      trueLabel="Without report"
    />,
    <ReferenceInput
      label="Patient LOB"
      source='patient#current_eligibility#lob_id'
      reference="line_of_business"
    >
      <SelectInput label="Patient LOB" optionText="description" />
    </ReferenceInput>,
    <DateInput label="Appointment creation from" source="created_at@_gte"/>,
    <DateInput label="Appointment creation to" source="created_at@_lte"/>,
    <QuickFilter source="assigned_to@_is_null" label="Unassigned" defaultValue={true} />,
    <QuickFilter source="assigned_to@_eq" label="Assigned to me" defaultValue={props.user_id} />,
    <UserInput label="Assigned to" source="assigned_to" />
  ];
};

const AppointmentBulkActionButtons = props => (
  <>
    <BulkArchiveAppointmentButton {...props} />
    <BulkUnarchiveAppointmentButton {...props} />
    <BulkReconcileAppointmentButton {...props} />
    <BulkUndoReconcileAppointmentButton {...props} />
    <BulkAppointmentAssignment {...props} />
  </>
);

const AppointmentListActions = () => (
  <TopToolbar>
    <FilterButton/>
    <ExportButton maxResults={-1}/>
  </TopToolbar>
);

const appointmentExporter = (appointments, fetchRelatedRecords) => {
  let promises = [
    fetchRelatedRecords(appointments, 'patient_id', 'patient'),
    fetchRelatedRecords(appointments, 'assigned_to', 'user'),
    fetchRelatedRecords(appointments, 'office_id', 'office')
  ];
  Promise.allSettled(promises).then(response => {
    const patients = response[0]?.value;
    const users = response[1]?.value;
    const offices = response[2]?.value;
    const appointmentsExpanded = appointments.map(appointment => {
      const patient = patients[appointment.patient_id];
      const user = users[appointment.assigned_to];
      const office = offices[appointment.office_id];
      return ({
        ...appointment,
        patient: patient === undefined ? appointment.patient_name : patient.name,
        lob: patient === undefined ? '' : patient.current_eligibility?.lob_id,
        assigned_user: user === undefined ? '' : user.name,
        office_name: office === undefined ? '' : office.name,
      });
    });
    const appointmentsForExport = appointmentsExpanded.map(appointment => {
      const {
        id,
        notes,
        patient_id,
        patient_name,
        user_id,
        appointment_type,
        appointment_status,
        provider_practitioner_id,
        did_not_match_insurance,
        reconciliation_report_id,
        assigned_to,
        ...appointmentForExport
      } = appointment;
      return appointmentForExport;
    });
    jsonExport(appointmentsForExport, {
        headers: ['date', 'type', 'status', 'insurance', 'insurance_id', 'patient_dob',
                  'patient', 'office_id', 'office_name', 'provider_name',
                  'archived', 'billed_status', 'is_billing_reconciled', 'warning',
                  'custom_note', 'created_at', 'lob', 'assigned_user']
    }, (err, csv) => {
        downloadCSV(csv, 'appointments');
    });
  });
};

const WorkingStatusIcon = () => {
  const record = useRecordContext();

  const today = new Date();
  const refDate = new Date(record.date);
  const dateDiff = Math.floor((today - refDate) / (1000*60*60*24));

  if (record.archived) {
    return null;
  }
  else if (['billed', 'with_omission', 'billed_pcp'].includes(record.billed_status)) {
    return (<CheckIcon fontSize="small" sx={{ color: '#808080' }}/>);
  }
  else {
    let color = '#0080FE';

    if (refDate <= today) {
      if (dateDiff < 30) {
        color = '#3BB143';
      }
      else if (dateDiff < 60) {
        color = '#FFF200';
      }
      else if (dateDiff >= 60) {
        color = '#D30000';
      }
    }

    return (<CircleIcon fontSize="small" sx={{ color: color }}/>);
  }
};

const AppointmentBilledStatus = () => {
  const record = useRecordContext();

  if (record.archived) {
    return null;
  }
  else {
    return (
      <ReferenceField source="billed_status" reference="appointment_billed_status">
        <TextField source="description" />
      </ReferenceField>
    );
  }
};

const AppointmentWarning = () => {
  const record = useRecordContext();

  return (
    (record.warning === true) ? <WarningAmberIcon sx={{ color: '#FF7E00' }}/> : null
  )
}

const AppointmentPatient = ({label, ...rest}) => {
  const record = useRecordContext();

  return (
    (record.patient_id !== null) ?
    <PatientNameReferenceField label={label} /> :
    <TextField label={label} source="patient_name" />
  )
}

const AppointmentProvider = ({label, ...rest}) => {
  const record = useRecordContext();

  return (
    (record.provider_practitioner_id !== null) ?
    (
      <ReferenceField
        source="provider_practitioner_id"
        reference="provider_practitioner"
      >
        <TextField label={label} source="name" />
      </ReferenceField>
    ) :
    <TextField label={label} source="provider_name" />
  )
}

const AppointmentReportButtons = () => {
  const record = useRecordContext();

  record.reconciliation_report_state = '';
  if (record.reconciliation_report_id !== null) {
    const {data: report, isLoading, error} = useGetOne(
      'reconciliation_report',
      {id: record.reconciliation_report_id}
    );
    record.reconciliation_report_state = (report !== undefined) ? report.state : '';
  }

  return (
    record.archived ? null : (
      record.reconciliation_report_id === null ?
        <Button
          component={Link}
          to="/reconciliation_report/create"
          onClick={(e) => {e.stopPropagation()}}
          state={{
            record:{
              patient_id: record.patient_id,
              next_appointment_date: record.date,
              reference_name: 'appointment',
              reference_id: record.id
            }
          }}
          children={<AddIcon />}
        /> :
        <>
          <Button
            component={Link}
            to={`/reconciliation_report/${record.reconciliation_report_id}/show`}
            onClick={(e) => {e.stopPropagation()}}
            children={<FactCheckIcon />}
          />
          <ReportStateChipField source="reconciliation_report_state" />
        </>
    )
  );
}

const AppointmentPanel = () => {
  return (
    <SimpleShowLayout >
      <TextField multiline sx={{width: '50%', whiteSpace: 'pre-wrap', display:'inline-block'}} source="custom_note" />
    </SimpleShowLayout>
  )
};

const AppointmentList = () => {
  const [userId, setUserId] = useState(null);

  if (userId === null) {
    getUserId().then((id) => {setUserId(id);});
  }

  return (userId === null) ? null : (
    <List
      actions={<AppointmentListActions/>}
      exporter={appointmentExporter}
      sort={{field: "date", order: "DESC"}}
      filters={appointmentFilters({'user_id': userId})}
      perPage={VITE_DEFAULT_LIST_PER_PAGE}
      filterDefaultValues={{ archived: false }}
    >
      <Datagrid
        bulkActionButtons={<AppointmentBulkActionButtons/>}
        expand={<AppointmentPanel/>}
        rowClick='show'
      >
        <WorkingStatusIcon label={<div>Working<br/>status</div>}/>
        <EnrichedAppointmentDateField label='Date' source='date'/>
        <ReferenceField source="type" reference="appointment_type">
          <TextField source="description" />
        </ReferenceField>
        <ReferenceField source="status" reference="appointment_status">
          <TextField source="description" />
        </ReferenceField>
        <AppointmentWarning label='Warning'/>
        <AppointmentPatient
          label="Member name"
          sortable={true}
          sortBy="patient_name_sort"
        />
        <OfficeReferenceField link={false} />
        <AppointmentProvider
          label="Provider"
          sortable={true}
          sortBy="provider_name_sort"
        />
        <AppointmentBilledStatus label={<div>Billed<br/>status</div>} />
        <ReferenceField reference="user" source="assigned_to" link={false}>
          <TextField source="name" />
        </ReferenceField>
        <AppointmentReportButtons label="Report" />
      </Datagrid>
    </List>
  );
}

export default AppointmentList;
