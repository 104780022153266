import {
  Edit,
  SimpleForm,
  ReferenceManyField,
  Datagrid,
  TextField,
  DateField,
  ReferenceField,
  TextInput,
  ReferenceInput,
  SelectInput,
  Toolbar,
  SaveButton,
  required,
  Labeled,
  useUpdate,
  useDataProvider,
  useRedirect,
  useNotify,
  Pagination,
} from 'react-admin';
import {useLocation} from 'react-router-dom';
import {VITE_DEFAULT_LISTSHOW_PER_PAGE} from "../config";

const GapPatientBatchUpdateToolbar = props => (
  <Toolbar {...props} >
    <SaveButton />
  </Toolbar>
);

const GapPatientBatchUpdate = () => {
  let { state } = useLocation();
  const [update] = useUpdate();
  const notify = useNotify();
  const redirect = useRedirect();
  const dataProvider = useDataProvider();

  const onSubmit = async (data) => {
    let promises = [];

    for (const index in state?.selectedIds) {
      const gapId = state?.selectedIds[index];
      dataProvider.
        getOne('gap', {id: gapId}).
        then(response => {
          const p = update(
              'gap',
              {
                id: gapId,
                data: {
                  working_status: data.working_status,
                  assigned_to: data.assigned_to,
                  comment: data.comment === undefined ? null : data.comment,
                },
                previousData: response.data
              }
          );
          promises.push(p);
        });
    }

    Promise.
      allSettled(promises).
      then(() => {
          notify('All gaps updated', { type: 'success' });
      }).
      catch((e) => {
          notify('Error updating gaps', { type: 'error' });
          console.log('error when updating gaps')
          console.log(e)
      }).
      finally(() => {
          redirect('show', 'gap_patient', state?.patient_id);
      });
  };

  return (
    <Edit actions={false}>
      <SimpleForm toolbar={<GapPatientBatchUpdateToolbar />} onSubmit={onSubmit}>
        <Labeled>
          <ReferenceManyField
            label='Gaps'
            reference='gap'
            source='id'
            target='patient_id'
            filter={{ 'id@_in': state?.selectedIds }}
            perPage={VITE_DEFAULT_LISTSHOW_PER_PAGE}
            pagination={<Pagination />}
          >
            <Datagrid rowClick={false} bulkActionButtons={false}>
              <ReferenceField
                reference='gap_status'
                source='status'
                label='Type'
                link={false}
              >
                <TextField source='description' />
              </ReferenceField>
              <ReferenceField
                reference='claim'
                source='latest_claim_id'
                label='Date'
                link={false}
              >
                <DateField source='min_service_date' />
              </ReferenceField>
              <ReferenceField
                reference='claim'
                source='source_claim_id'
                label='Source'
                link={false}
              >
                <TextField source='claim_nbr' />
              </ReferenceField>
              <TextField source='diag_code' label='Diagnosis' />
              <ReferenceField
                label='HCC v24'
                source='diag_code'
                reference='diagnosis_conditions_agg'
              >
                <TextField source='conditions_v24'/>
              </ReferenceField>
              <ReferenceField
                label='HCC v28'
                source='diag_code'
                reference='diagnosis_conditions_agg'
              >
                <TextField source='conditions_v28'/>
              </ReferenceField>
              <ReferenceField
                reference='diagnosis'
                source='diag_code'
                label='Diagnosis Description'
                link={false}
              >
                <TextField source='description' />
              </ReferenceField>
              <ReferenceField
                reference='gap_working_status'
                source='working_status'
                label='Disposition'
                link={false}
              >
                <TextField source='description' />
              </ReferenceField>
            </Datagrid>
          </ReferenceManyField>
        </Labeled>
        <ReferenceInput
          reference='gap_working_status'
          source='working_status'
          label='Disposition'
          sort={{ field: 'description', 'order': 'ASC' }}
        >
          <SelectInput
            optionText='description'
            label='Disposition'
            validate={required()}
          />
        </ReferenceInput>
        <TextInput fullWidth multiline source='comment' />
      </SimpleForm>
    </Edit>
  );
};

export default GapPatientBatchUpdate;
