import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import AppointmentList from "./AppointmentList";
import AppointmentEdit from "./AppointmentEdit";
import AppointmentShow from "./AppointmentShow";

const resource = {
  list: AppointmentList,
  edit: AppointmentEdit,
  show: AppointmentShow,
  icon: CalendarMonthIcon,
};

export default resource;