import {
  BooleanField, Button,
  Datagrid,
  DateField, DeleteButton, EditButton, Link,
  NumberField,
  Pagination,
  ReferenceField,
  ReferenceManyField,
  Show,
  SimpleShowLayout,
  TextField,
  TopToolbar,
  useDataProvider,
  usePermissions,
  useRecordContext,
  WithRecord,
  Labeled,
  SingleFieldList,
  ChipField
} from "react-admin";
import {FormControl, Select, MenuItem, InputLabel, Breadcrumbs, Typography, Input} from "@mui/material"
import AuditStatusChipField from "./AuditStatusChipField";
import PatientNameReferenceField from "../patient/PatientNameReferenceField";
import CMSStatusField from "../claim/CMSStatusField";
import AuditClaimStatusIcon from "./AuditClaimStatusIcon";
import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import DoneIcon from "@mui/icons-material/Done";
import AssessmentIcon from '@mui/icons-material/Assessment';
import {SapiensFieldsRow} from "../components/SapiensFieldsRow";
import {startAudit} from "./common";
import StartIcon from "@mui/icons-material/NotStarted";
import {useApolloClient} from "@apollo/client";
import {getUserId} from "../auth/sapiensUser";
import GatekeeperReferenceField from "../gatekeeper/GatekeeperReferenceField";
import {VITE_DEFAULT_LISTSHOW_PER_PAGE} from "../config";


export const StartButton = (props) => {
  const navigate = useNavigate();
  const apolloClient = useApolloClient()
  let [running, setRunning] = useState(false);

  const {record} = props;

  let handleClick = (event) => {
    event.stopPropagation();
    setRunning(true);
    startAudit(apolloClient, record.id)
      .then(() => {
        navigate('/audit');
      })
      .finally(() => {
        setRunning(false);
      })
  }

  return (
    <>
      <Button
        startIcon={<StartIcon />}
        onClick={handleClick}
        disabled={running}
        label="Start"/>
      {running ? 'Starting...' : ''}
    </>
  );
}


export const MarkFinishedButton = (props) => {
  const navigate = useNavigate();
  const dataProvider = useDataProvider();


  let [running, setRunning] = useState(false);

  const {record} = props;

  let handleClick = (event) => {
    event.stopPropagation();
    setRunning(true);
    dataProvider.update('audit', {id: record.id, data: {status: 'finished'}})
      .then(() => {
        navigate('/audit');
      })
      .finally(() => {
        setRunning(false);
      })
  }

  return (
    <Button
      startIcon={<DoneIcon/>}
      onClick={handleClick}
      disabled={running}
      label="Mark finished"/>
  );
}


export const ViewResultsButton = (props) => {
  const navigate = useNavigate();
  const {record} = props;

  let handleClick = (event) => {
    event.stopPropagation();
    navigate('/audit/' + record.id + '/results');
  }

  return (
    <Button
      startIcon={<AssessmentIcon />}
      onClick={handleClick}
      label="Results"/>
  );
}

const AuditShowActions = (props) => {
  const record = useRecordContext(props);
  const { permissions } = usePermissions();
  if (!record) return null;
  return (
    <TopToolbar>
      { (record.status === 'started') ? <MarkFinishedButton record={record}/> : null}
      { (record.status === 'new' && permissions?.includes('supervisor')) ? <DeleteButton/> : null}
      { (record.status === 'new' || permissions?.includes('supervisor')) ? <EditButton /> : null}
      { (record.status === 'new') ? <StartButton record={record}/> : null}
      { (record.status === 'finished') ? <ViewResultsButton record={record}/> : null}
    </TopToolbar>
  );
}

const CustomBreadcrumb = () => {
  const record = useRecordContext();
  if (record === undefined) return null;
  return (
    <Breadcrumbs aria-label="breadcrumb" sx={{paddingTop: 1, paddingLeft: 2}}>
      <Link to="/audit">
        Audits
      </Link>
      <Typography>
        {`Audit ${record.id}`}
      </Typography>
    </Breadcrumbs>
  );
};

export const AuditShowLayout = (props) => {
  const [auditStatusSelectState, setAuditStatusSelectState] = useState('both');
  const [loading, setLoading] = useState(true);
  const [canEditAudit, setCanEditAudit] = useState(false);
  const [patientFilter, setPatientFilter] = useState({});
  const [statusFilter, setStatusFilter] = useState({});
  const record = useRecordContext(props);

  // make the filter by combining the objects together
  let filter = Object.assign({}, patientFilter, statusFilter);

  const onPatientFilterChange = (event) => {
    setPatientFilter({'claim#patient#name@_ilike': event.target.value})
  }

  const onAuditStatusSelectChange = (event, value) => {
    let newValue = value.props.value;
    setAuditStatusSelectState(newValue);
    let statusFilterDict;
    if (newValue && newValue === 'both') {
      statusFilterDict = {}
    } else if (newValue) {
      statusFilterDict = {status: newValue};
    }
    setStatusFilter(statusFilterDict)
  }

  useEffect(() => {
    if (record === undefined) return;
    getUserId().then((id) => {
      setLoading(false);
      setCanEditAudit(record.status === 'started' && record.user_id === parseInt(id));
    });
  }, [record]);

  if (loading) return null;
  return (
    <>
      <CustomBreadcrumb/>
      <SimpleShowLayout>
        <SapiensFieldsRow>
          <GatekeeperReferenceField />
          <DateField source="date_from"/>
          <DateField source="date_to"/>
          <NumberField source="percentage" />
          <BooleanField source="hcc_only" />
          <TextField source="scope" />
          <ReferenceField label='Auditor' reference='user' source='user_id' link={false}>
            <TextField source='name'/>
          </ReferenceField>
          <Labeled label='Audit diagnosis filter'>
            <ReferenceManyField reference='audit_diagnosis_filter' target='audit_id'>
              <SingleFieldList linkType={false}>
                <ChipField source="diagnosis_id" size="small" />
              </SingleFieldList>
            </ReferenceManyField>
          </Labeled>
        </SapiensFieldsRow>
        <AuditStatusChipField/>
        <ReferenceManyField
          label="Claims"
          reference="audit_claim"
          source="id"
          target="audit_id"
          sort={{field: 'claim.max_service_date,claim.patient_id,claim_id', order: 'asc,asc,asc'}}
          filter={filter}
          perPage={VITE_DEFAULT_LISTSHOW_PER_PAGE}
          pagination={<Pagination />}>
          <FormControl sx={{ m: 1, minWidth: 120 }} label="Status" size="small">
            <InputLabel>Audit status</InputLabel>
            <Select value={auditStatusSelectState} onChange={onAuditStatusSelectChange}>
              <MenuItem value='both'>Both</MenuItem>
              <MenuItem value='audited'>Audited</MenuItem>
              <MenuItem value='pending'>Pending</MenuItem>
            </Select>
          </FormControl>
          <FormControl sx={{m:1, minWidth: 250}} size="small">
            <InputLabel>Patient search</InputLabel>
            <Input defaultValue="" onChange={onPatientFilterChange}/>
          </FormControl>
          <Datagrid rowClick={canEditAudit ? 'edit': 'show'} bulkActionButtons={false}>
            <ReferenceField link={false} label="Claim Number" reference="claim" source='claim_id'>
              <TextField source="claim_nbr" />
            </ReferenceField>
            <DateField label="Service date" source="claim.max_service_date" />
            <PatientNameReferenceField label="Member name" source='claim.patient_id' link={false}/>
            <ReferenceField link={false} label="Service provider" reference="provider" source="claim.max_service_provider_id">
              <TextField source="name" />
            </ReferenceField>
            <BooleanField label="Manually added" source='is_manually_added'/>
            <WithRecord label="CMS Status" render={record => <CMSStatusField  record={record.claim}/>}/>
            <AuditClaimStatusIcon label="Audited"/>
          </Datagrid>
        </ReferenceManyField>
      </SimpleShowLayout>
    </>
  );
}

export const AuditShow = () => {
  return (
    <Show actions={<AuditShowActions />}>
      <AuditShowLayout/>
    </Show>
  );
}


export default AuditShow;
