import {
  BulkDeleteButton,
  Datagrid,
  DateField,
  DateInput,
  List,
  ReferenceField,
  TextField,
} from "react-admin";
import PatientNameReferenceField from "../patient/PatientNameReferenceField";
import {useParams} from "react-router-dom";
import ProviderPractitionerInput from "../providerpractitioner/ProviderPractitionerInput";
import ReportStateInput from "./ReportStateInput";
import PatientInput from "../patient/PatientInput";
import ReportStateChipField from "./ReportStateChipField";
import {BulkDownloadReportsButton, BulkMarkSentReportsButton, BulkSentReportsByEmailButton} from "./ReportShowButtons";
import GatekeeperInput from "../providerpractitioner/GatekeeperInput";
import UserInput from "../user/UserInput";
import InsuranceProviderInput from "../patient/InsuranceProviderInput";
import {VITE_DEFAULT_LIST_PER_PAGE} from "../config";


const ReconciliationReportsBulkActionButtons = props => (
  <>
    <BulkDownloadReportsButton {...props} />
    <BulkMarkSentReportsButton {...props} />
    <BulkSentReportsByEmailButton {...props} />
    <BulkDeleteButton {...props} />
  </>
);



export const ReconciliationReportListView = ({bulkActionButtons}) => (
  <Datagrid bulkActionButtons={bulkActionButtons} rowClick="show">
    <ReferenceField reference='provider_practitioner' source='provider_practitioner_id' link={false}>
      <TextField source='name'/>
    </ReferenceField>
    <DateField source="next_appointment_date"/>
    <PatientNameReferenceField />
    <ReferenceField
      label='Main identifier'
      reference='patient' source='patient_id' link={false}>
      <TextField source='current_eligibility.id_value'/>
    </ReferenceField>
    <ReferenceField reference='user' source='user_id' link={false}>
      <TextField source="name" />
    </ReferenceField>
    <DateField source="created_at"/>
    <ReportStateChipField source="state" label="Status" />
  </Datagrid>
);


const ReconciliationReportList = (props) => {
  const reconciliationReportFilters = [
    <PatientInput label="Patient" source="patient_id" alwaysOn/>,
    <ReportStateInput label="State" source="state@_in" alwaysOn/>,
    <ProviderPractitionerInput label="Practitioner" source='provider_practitioner_id' alwaysOn/>,
    <InsuranceProviderInput label="Insurance provider" source="patient#current_eligibility#insurance_provider_id" alwaysOn />,
    <GatekeeperInput label="PCP" source="patient#current_eligibility#gatekeeper_id" />,
    <DateInput label="Next appointment from" source='next_appointment_date@_gte' />,
    <DateInput label="Next appointment to" source='next_appointment_date@_lte' />,
    <DateInput label="Created from" source="created_at@_gte" parse={v => {let d = new Date(`${v}T00:00:00`); return d.toISOString()}}/>,
    <DateInput label="Created to" source="created_at@_lte" parse={v => {let d = new Date(`${v}T23:59:59`); return d.toISOString()}}/>,
    <UserInput label="User" source="user_id" />,
  ]

  let filter;
  const { patientId } = useParams();

  if (!patientId){
    filter = {};
  } else {
    filter = {patient_id: patientId};
  }

  return (
    <List
      exporter={false}
      perPage={VITE_DEFAULT_LIST_PER_PAGE}
      sort={{field: 'created_at', order: 'DESC'}}
      filters={reconciliationReportFilters}
      filter={filter}
      {...props }
    >
      <ReconciliationReportListView bulkActionButtons={<ReconciliationReportsBulkActionButtons/>}/>
    </List>);
};

export default ReconciliationReportList;
