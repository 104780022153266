import {
  Datagrid,
  DateField,
  List,
  TextField,
  TextInput,
  number,
  DateInput,
  ReferenceField,
  Button,
  useListContext,
  useUnselectAll,
  useDataProvider
} from "react-admin";
import PatientNameReferenceField from "../patient/PatientNameReferenceField";
import CMSStatusField from "./CMSStatusField";
import PatientInput from "../patient/PatientInput";
import ConditionYearInput from "./ConditionYearInput";
import {DiagnosisArrayInput} from "./DiagnosisInput";
import GatekeeperInput from "../providerpractitioner/GatekeeperInput";
import CMSStatusInput from "./CMSStatusInput";
import ServiceCategoryField from "./ServiceCategoryField";
import ServiceCategoryInput from "./ServiceCategoryInput";
// import ClaimDiagnosesField from "./ClaimDiagnosesField";
import {useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {Typography} from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import {addClaimsToAudit} from "../audit/common";
import {useApolloClient} from "@apollo/client";
import {VITE_DEFAULT_LIST_PER_PAGE} from "../config";

const validateClaim = [number()]

// This more or less works. Spaces in name are not interpreted correctly.
const claimFilters = [
  <TextInput label="Claim Number" source="claim_nbr" resettable={true} validate={validateClaim} alwaysOn />,
  <PatientInput label="Patient" source="patient_id" name='patient_id' alwaysOn/>,
  <ConditionYearInput label="Condition year" source="condition_years#condition_year_id@_eq"/>,
  <DateInput label="From date" source="max_service_date@_gte" />,
  <DateInput label="To date" source="max_service_date@_lte" />,
  <ServiceCategoryInput label="Service category" source="service_category@_in" />,
  <DiagnosisArrayInput label="Includes one or more Diagnosis" source='diagnoses#diag_code@_in'/>,
  <CMSStatusInput label="CMS Status" source="mao_flag@_in" />,
  <TextInput label="Model 24 HCC code" source="diagnoses#diagnosis#conditions_v24#condition_id@_eq"/>,
  <TextInput label="Model 28 HCC code" source="diagnoses#diagnosis#conditions_v84#condition_id@_eq"/>,
  <GatekeeperInput label="PCP" source="gk_id" />,
];

export const BulkAddClaimToAuditButton = props => {
  const navigate = useNavigate();
  const apolloClient = useApolloClient();
  const [running, setRunning] = useState(false);
  const {selectedIds, resource} = useListContext();
  const unselectAll = useUnselectAll(resource);

  const auditId = props.auditId;
  // This button is only active when the view is opened from an audit with the origin audit id
  if (!auditId) return null;
  if (!selectedIds || selectedIds.length === 0) return null;


  const addSelected = async () => {
    setRunning(true);
    addClaimsToAudit(apolloClient, auditId, selectedIds.map(String))
      .then(() => {
        setRunning(false);
        unselectAll();
        navigate("", {});  // clear the state
        navigate(`/audit/${auditId}`);
      });
  };

  return (
    <>
      <Button
        label={`Add claims to audit ${auditId}`}
        disabled={running}
        onClick={() => addSelected()}
      >
        <AddIcon />
      </Button>
      <Backdrop
        sx={{ color: '#fff', zIndex:  1000 }}
        open={running}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
};

const ClaimListBulkActionButtons = props => (
  <>
    <BulkAddClaimToAuditButton {...props} />
  </>
);

export const ClaimListView = props => (
  <Datagrid rowClick="show" bulkActionButtons={<ClaimListBulkActionButtons auditId={props.auditId} />}>
    <TextField source="claim_nbr" label="Claim Number" />
    <PatientNameReferenceField label="Member name" />
    <ReferenceField label="Service provider" reference="provider" source="max_service_provider_id">
      <TextField source="name" />
    </ReferenceField>
    <CMSStatusField label="CMS Status"/>
    <ServiceCategoryField label="Service category"/>
    {/*<ClaimDiagnosesField label="Diagnoses"/>*/}
    <DateField source="max_service_date" label="Service date"></DateField>
  </Datagrid>
);


export const ClaimList = (props) => {
  let { state } = useLocation();
  const dataProvider = useDataProvider();
  const [filter, setFilter] = useState({});
  const [auditId, setAuditId] = useState(null);
  if (auditId === null) {
    setAuditId(state?.audit_id);
  }
  if (!!auditId) {
    dataProvider.getList('audit_claim', {filter: { audit_id: auditId }})
      .then((data) => {
        const excludeClaimIds = data['data'].map(ac => ac.claim_id);
        if (excludeClaimIds.length !== filter["id@_nin"]?.length) {
          setFilter({ "id@_nin": excludeClaimIds });
        }
      });
  }

  return (
    <>
      {auditId && <Typography>Select claims to add to audit {auditId}</Typography>}
      <List
        filter={filter}
        filters={claimFilters}
        sort={{field: 'max_service_date', order: 'DESC'}}
        perPage={VITE_DEFAULT_LIST_PER_PAGE}
        {...props}
      >
        <ClaimListView auditId={auditId} />
      </List>
    </>
  )};

export default ClaimList;
