import {Datagrid, DateField, DateInput, List, Pagination, ReferenceField, TextField, TextInput} from 'react-admin';
import BillingClaimPatientField from "./BillingClaimPatientField";
import GatekeeperReferenceField from "../gatekeeper/GatekeeperReferenceField";
import PatientInput from "../patient/PatientInput";
import GatekeeperInput from "../providerpractitioner/GatekeeperInput";
import BilledStatusInput from "./BilledStatusInput";
import ConditionYearInput from "../claim/ConditionYearInput";
import {DiagnosisArrayInput} from "../claim/DiagnosisInput";
import {VITE_DEFAULT_LIST_PER_PAGE} from "../config";


const filters = [
  <PatientInput label="Patient" source="patient_id" name='patient_id' alwaysOn/>,
  <TextInput label="Visit Id" source="visit_id" resettable={true} alwaysOn />,
  <GatekeeperInput label="PCP" source="gk_id" alwaysOn/>,
  <BilledStatusInput source="billed_status@_in" alwaysOn/>,
  <DateInput label="Service date from" source="service_date@_gte" />,
  <DateInput label="Service date to" source="service_date@_lte" />,
  <DateInput label="Billed date from" source="billeddate@_gte" />,
  <DateInput label="Billed date to" source="billeddate@_lte" />,
  <ConditionYearInput label="Condition year" source="condition_years#condition_year_id@_eq"/>,
  <DiagnosisArrayInput label="Includes one or more Diagnosis" source='diagnoses#diag_code@_in'/>
];

const BillingClaimList = () => (
  <List
    pagination={<Pagination/>}
    perPage={VITE_DEFAULT_LIST_PER_PAGE}
    filters={filters}
    sort={{field: 'service_date', order: 'DESC'}} >

    <Datagrid rowClick="show">
      <ReferenceField source="visit_id" reference="visit" link='show'/>
      <GatekeeperReferenceField />
      <BillingClaimPatientField label='Patient' />
      <DateField source="service_date" />
      <DateField source="billed_date" />
      <TextField source="billed_status"/>
      <TextField source="origin_created_by"/>
    </Datagrid>
  </List>
)

export default BillingClaimList;
