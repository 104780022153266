import {
  Datagrid,
  Pagination,
  ReferenceField,
  ReferenceManyField,
  TextField,
} from "react-admin";

import {CondyearConditionClaimStatusField} from "../claim/CondyearConditionClaimStatusField"
import {cyToYear} from "../utils";
import {VITE_DEFAULT_LISTSHOW_PER_PAGE} from "../config";


export const PatientConditionsList = ({model, historical, future, statusAsIcon, ...props}) => (
  <ReferenceManyField
    reference="patient_hcc_current"
    target="patient_id"
    filter={{model: model}}
    sort={{field: 'condition_id', order: 'ASC'}}
    perPage={50}
    pagination={<Pagination/>}
    {...props}
  >
    <Datagrid bulkActionButtons={false}>
      <TextField source="condition_id" label="Condition"/>
      <ReferenceField source="model_condition_id" reference="condition" label="Description">
        <TextField source="description" />
      </ReferenceField>
      {
        historical ? (
          <CondyearConditionClaimStatusField
            conditionyear_lt="255"
            label={<div>Historical</div>}
            source="status_history_h"
            statusAsIcon={statusAsIcon}
          />
        ) : null
      }
      <CondyearConditionClaimStatusField
        conditionyear="255"
        label={<div>255<br/>({cyToYear(255)})</div>}
        source="status_history"
        statusAsIcon={statusAsIcon}
      />
      <CondyearConditionClaimStatusField
        conditionyear="265"
        label={<div>265<br/>({cyToYear(265)})</div>}
        source="status_prior"
        statusAsIcon={statusAsIcon}
      />
      {
        future ? (
          <CondyearConditionClaimStatusField
            conditionyear="270"
            label={<div>270<br/>({cyToYear(270)})</div>}
            source="status_future"
            statusAsIcon={statusAsIcon}
          />
        ) : null
      }
      <CondyearConditionClaimStatusField
        conditionyear="275"
        label={<div>275<br/>({cyToYear(275)})</div>}
        source="status_current"
        statusAsIcon={statusAsIcon}
      />
    </Datagrid>
  </ReferenceManyField>
);

export default PatientConditionsList;
