import {
  Show,
  SimpleShowLayout,
  TextField,
  DateField,
  ReferenceField,
  ReferenceManyField,
  Datagrid,
  Pagination,
} from 'react-admin';
import PatientNameReferenceField from '../patient/PatientNameReferenceField';
import GatekeeperReferenceField from '../gatekeeper/GatekeeperReferenceField';
import {VITE_DEFAULT_LISTSHOW_PER_PAGE} from "../config";

export const GapShow = () => (
  <Show>
    <SimpleShowLayout>
      <PatientNameReferenceField label='Member Name' />
      <GatekeeperReferenceField source='patient.current_eligibility.gatekeeper_id' label='PCP' />
      <TextField source='patient.current_eligibility.id_value' label='Member ID' />
      <TextField source='patient.date_of_birth' label='DOB' />
      <ReferenceField
        reference='gap_status'
        source='status'
        label='Type'
        link={false}
      >
        <TextField source='description' />
      </ReferenceField>
      <ReferenceField
        reference='claim'
        source='latest_claim_id'
        label='Date'
        link='show'
      >
        <DateField source='min_service_date' />
      </ReferenceField>
      <ReferenceField
        reference='claim'
        source='source_claim_id'
        label='Source'
        link='show'
      >
        <TextField source='claim_nbr' />
      </ReferenceField>
      <TextField source='diag_code' label='Diagnosis' />
      <ReferenceField
        label='HCC v24'
        source='diag_code'
        reference='diagnosis_conditions_agg'
      >
        <TextField source='conditions_v24'/>
      </ReferenceField>
      <ReferenceField
        label='HCC v28'
        source='diag_code'
        reference='diagnosis_conditions_agg'
      >
        <TextField source='conditions_v28'/>
      </ReferenceField>
      <ReferenceField
        reference='diagnosis'
        source='diag_code'
        label='Diagnosis Description'
        link={false}
      >
        <TextField source='description' />
      </ReferenceField>
      <ReferenceField
        reference='gap_working_status'
        source='working_status'
        label='Disposition'
        link={false}
      >
        <TextField source='description' />
      </ReferenceField>
      <ReferenceManyField
        label='Gap History'
        reference='gap_history'
        source='id'
        target='gap_id'
        sort={{field: 'id', order: 'DESC'}}
        perPage={VITE_DEFAULT_LISTSHOW_PER_PAGE}
        pagination={<Pagination />}
      >
        <Datagrid rowClick={false} bulkActionButtons={false} hover={false}>
          <DateField source='date' />
          <TextField source='message' />
          <DateField source='date_of_service' />
          <ReferenceField
            reference='claim'
            source='claim_id'
            label='Related Claim'
            link='show'
          >
            <TextField source='claim_nbr' />
          </ReferenceField>
          <ReferenceField
            label='Event by'
            reference='user'
            source='user_id'
            link={false}
          >
            <TextField source='name' />
          </ReferenceField>
        </Datagrid>
      </ReferenceManyField>
    </SimpleShowLayout>
  </Show>
);

export default GapShow;
