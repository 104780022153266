import {
  List,
  Datagrid,
  ReferenceField,
  TextField,
  TopToolbar,
} from "react-admin";
import RuleActionValues from "./RuleActionValues";
import {VITE_DEFAULT_LIST_PER_PAGE} from "../config";

const RuleActionListActions = () => (
  <TopToolbar>
  </TopToolbar>
);

const RuleActionList = () => {
  return(
    <List
      actions={<RuleActionListActions/>}
      exporter={false}
      perPage={VITE_DEFAULT_LIST_PER_PAGE}
    >
      <Datagrid rowClick={false} bulkActionButtons={false}>
        <ReferenceField source="rule_id" reference="rule" link={false}>
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField source="type" reference="rule_action_type">
          <TextField source="description" />
        </ReferenceField>
        <RuleActionValues mode="list" label="Value" />
      </Datagrid>
    </List>
  );
}

export default RuleActionList;
