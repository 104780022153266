import {
  BooleanField,
  Button,
  Datagrid,
  DateField,
  List,
  NumberField,
  ReferenceField,
  TextField,
  FunctionField,
  useListContext,
  useNotify,
  TextInput,
  DateInput,
  SelectInput,
  TopToolbar,
  CreateButton,
  useCreate,
  useRedirect,
} from 'react-admin';
import AuditStatusChipField from "./AuditStatusChipField";
import AuditResultInListField from "./AuditResultInListField";
import StartIcon from "@mui/icons-material/NotStarted";
import {useState} from "react";
import GatekeeperInput from "../providerpractitioner/GatekeeperInput";
import UserInput from "../user/UserInput";
import {startAudit} from "./common";
import {useApolloClient} from "@apollo/client";
import AddIcon from '@mui/icons-material/Add';
import GatekeeperReferenceField from "../gatekeeper/GatekeeperReferenceField";
import {VITE_DEFAULT_LIST_PER_PAGE} from "../config";

export const BulkStartAuditsButton = () => {
  const { selectedIds, resource } = useListContext();
  const apolloClient = useApolloClient();
  const notify = useNotify();
  const [running, setRunning] = useState(false);

  const startSelected = async () => {
    let promises = [];
    setRunning(true);

    for (let id of selectedIds) {
      promises.push(startAudit(apolloClient, id));
    }

    Promise.allSettled(promises)
      .then((results) => {
        let good = 0;
        let bad = 0;
        for (let r of results) {
          if (r.status === 'fulfilled') {
            good++;
          } else {
            bad++;
          }
        }
        let type = 'success';
        let m = `${good} audits starting`;
        if (bad > 0) {
          m += `. (${bad} failed)`;
          type = 'warning';
        }
        notify(m, { type: type });
      })
      .catch((e) => {
        notify(`Error starting audits`, { type: 'error' });
        console.log('Error starting audits')
        console.log(e)
      })
      .finally(() => {
        setRunning(false);
      });
  };

  return (
    <Button
      label="Start"
      disabled={running}
      onClick={() => startSelected()}
    >
      <StartIcon />
    </Button>
  );
}


const BulkActionButtons = () => (
  <>
    <BulkStartAuditsButton/>
  </>
);

export const CreateEmptyAuditButton = () => {
  const redirect = useRedirect();
  const [running, setRunning] = useState(false);
  const [create, { isLoading, error }] = useCreate();

  const createEmptyAudit = async () => {
      setRunning(true);

      create('audit', {data:{}}, {returnPromise: true})
      .then((data) => {
        setRunning(false);
        redirect('show', 'audit', data.id);
      })
  };

  return (
    <Button
      label="New empty audit"
      children={<AddIcon />}
      disabled={running}
      onClick={() => createEmptyAudit()}
    />
  );
}

const AuditListToolbar = () => (
  <TopToolbar>
    <CreateButton />
    <CreateEmptyAuditButton />
  </TopToolbar>
);

const filters = [
  <TextInput label="Audit number" source="id" resettable={true} alwaysOn />,
  <DateInput label="Created date from" source="created_at@_gte" alwaysOn/>,
  <SelectInput label="Status" source="status" alwaysOn choices={[
    { id: "new", name: "New" },
    { id: "started", name: "Started" },
    { id: "finished", name: "Finished" },
  ]}/>,
  <GatekeeperInput label="PCP" source="gatekeeper_id" alwaysOn/>,
  <UserInput label="User" source="user_id" alwaysOn />,
];

const AuditList = () => {
  return (
    <List
      exporter={false}
      filters={filters}
      actions={<AuditListToolbar />}
      perPage={VITE_DEFAULT_LIST_PER_PAGE}
    >
      <Datagrid rowClick='show' bulkActionButtons={<BulkActionButtons/>}>
        <TextField source="id"/>
        <DateField source="created_at"/>
        <GatekeeperReferenceField />
        <DateField source="date_from"/>
        <DateField source="date_to"/>
        <BooleanField source="hcc_only"/>
        <NumberField source="percentage"/>
        <ReferenceField label='Done' reference='audit_meta' source="id" target="id">
          <FunctionField render={record => `${record.done_cnt} / ${record.total_cnt}`}/>
        </ReferenceField>
        <AuditResultInListField label='Accuracy'/>
        <TextField source="scope"/>
        <AuditStatusChipField source="status" includeLabel={false}/>
        <ReferenceField source="user_id" reference="user" link={false}>
          <TextField source="name"/>
        </ReferenceField>
      </Datagrid>
    </List>
  );
}
export default AuditList;
