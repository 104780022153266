import {
  ReferenceInput,
  AutocompleteInput,
  Labeled,
  ReferenceField,
  FunctionField,
  useRecordContext,
  required,
} from "react-admin";
import {useWatch} from "react-hook-form"
import DiagnosisInput from "../claim/DiagnosisInput";

const RuleActionDiagnosis = ({mode, ...rest}) => {
  return (
    mode === "list" ?
      <ReferenceField
        source="value"
        reference="diagnosis"
        label="Value"
      >
        <FunctionField render={record => `[${record.id}] ${record.description}`}/>
      </ReferenceField> :
      (
        mode === "edit" ?
        <DiagnosisInput
          source="value"
          reference="diagnosis"
          label="Diagnosis"
          validate={required()}
        /> :
        <Labeled label="Diagnosis">
          <ReferenceField
            source="value"
            reference="diagnosis"
          >
            <FunctionField render={record => `[${record.id}] ${record.description}`}/>
          </ReferenceField>
        </Labeled>
      )
  );
};

const RuleActionCondition = ({mode, ...rest}) => {
  return (
    mode === "list" ?
      <ReferenceField
        source="value"
        reference="condition"
        label="Value"
      >
        <FunctionField render={record => `[${record.model} - ${record.cid}] ${record.description}`}/>
      </ReferenceField> :
      (
        mode === "edit" ?
        <ReferenceInput
          source="value"
          reference="condition"
          sort={{ field: "cid", order: "ASC" }}
        >
          <AutocompleteInput
            optionText={(condition) => condition?.id // the empty choice is { id: '' }
              ? `[${condition.model} - ${condition.cid}] ${condition.description}` : ''}
            filterToQuery={(searchText) => ({'description@_ilike,model@_ilike,id@_ilike': searchText})}
            label="Condition"
            validate={required()}
            isOptionEqualToValue={(option, value) =>  option.id === value.id}  //NOTE: React Admin replaces this with their implementation
            sx={{minWidth: 500}}
            componentsProps={{
              paper: {
                sx: {
                  width: 600
                }
              }
            }}
          />
        </ReferenceInput> :
        <Labeled label="Condition">
          <ReferenceField
            source="value"
            reference="condition"
          >
            <FunctionField render={record => `[${record.model} - ${record.cid}] ${record.description}`}/>
          </ReferenceField>
        </Labeled>
      )
  );
};

const RuleActionValues = ({mode, ...rest}) => {
  const record = useRecordContext();
  const typeWatch = (mode === "edit") ? useWatch({ name: 'type' }) : null;
  const actionType = record?.type || typeWatch;

  if (actionType == "smart_diagnosis") {
    return (
      <RuleActionDiagnosis mode={mode} {...rest} />
    );
  }
  else if (actionType == "smart_condition") {
    return (
      <RuleActionCondition mode={mode} {...rest} />
    );
  }
  else {
    return (<></>);
  }
};

export default RuleActionValues;
