import {
  Show,
  SimpleShowLayout,
  TextField,
  DateField,
  ReferenceField,
  ReferenceManyField,
  Datagrid,
  TopToolbar,
  EditButton,
  usePermissions,
  Pagination
} from "react-admin";
import {VITE_DEFAULT_LISTSHOW_PER_PAGE} from "../config";

const OfficeShowTopToolbar = () => {
  const { permissions } = usePermissions();
  if (permissions === undefined) return null;

  return (
    <TopToolbar>
      { permissions.includes('supervisor') ? <EditButton/> : null }
    </TopToolbar>
  )
}

const OfficeShow = () => (
  <Show actions={<OfficeShowTopToolbar />}>
    <SimpleShowLayout>
      <TextField source="name"/>
      <ReferenceField source="gatekeeper_id" reference="gatekeeper" link="show">
        <TextField source="name" />
      </ReferenceField>
      <ReferenceManyField
        label="Contracts"
        reference="contract"
        source="id"
        target="office_id"
        perPage={VITE_DEFAULT_LISTSHOW_PER_PAGE}
        pagination={<Pagination />}
      >
        <Datagrid rowClick={false} bulkActionButtons={false}>
          <ReferenceField source="insurance_provider_id" reference="insurance_provider">
            <TextField source="description" />
          </ReferenceField>
          <TextField source="code"/>
          <DateField source="from"/>
          <DateField source="to"/>
          <EditButton />
        </Datagrid>
      </ReferenceManyField>
    </SimpleShowLayout>
  </Show>
);

export default OfficeShow;