import {
  Create,
  SimpleForm,
  ReferenceInput,
  AutocompleteInput,
  required,
} from "react-admin";
import {useLocation} from "react-router-dom";
import RuleConditionValues from "./RuleConditionValues";

const RuleConditionCreate = () => {
  let { state } = useLocation();

  const redirectFx = (resource, id, data) => {
    return `rule/${data.rule_id}`;
  }

  const transformFx = (data) => {
    return {
      ...data,
      rule_id: state?.rule_id
    };
  }

  return (
    <Create redirect={redirectFx} transform={transformFx}>
      <SimpleForm>
        <ReferenceInput
          source="class_id"
          reference="rule_condition_class"
          sort={{ field: "name", order: "ASC" }}
        >
          <AutocompleteInput
            optionText="name"
            validate={required()}
            filterToQuery={(searchText) => ({ "name@_ilike": searchText })}
          />
        </ReferenceInput>
        <RuleConditionValues mode="edit" />
      </SimpleForm>
    </Create>
  );
};

export default RuleConditionCreate;
