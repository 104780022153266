import {
  List,
  Datagrid,
  TextField,
  TextInput,
} from "react-admin";
import {VITE_DEFAULT_LIST_PER_PAGE} from "../config";

const ruleFilters = [
  <TextInput source="name" alwaysOn/>,
];

const RuleList = () => {
  return(
    <List
      exporter={false}
      perPage={VITE_DEFAULT_LIST_PER_PAGE}
      sort={{field: "name", order: "ASC"}}
      filters={ruleFilters}
    >
      <Datagrid rowClick="show" bulkActionButtons={false}>
        <TextField source="name" />
      </Datagrid>
    </List>
  );
}

export default RuleList;
