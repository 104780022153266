import {
  Edit,
  SimpleForm,
  ReferenceInput,
  AutocompleteInput,
  Toolbar,
  SaveButton,
  required,
} from "react-admin";
import RuleActionValues from "./RuleActionValues";

const RuleActionEditToolbar = props => (
  <Toolbar {...props} >
    <SaveButton />
  </Toolbar>
);

const RuleActionEdit = () => {
  const redirectFx = (resource, id, data) => {
    return `rule/${data.rule_id}`;
  }

  return (
    <Edit actions={false} redirect={redirectFx}>
      <SimpleForm toolbar={<RuleActionEditToolbar />}>
        <ReferenceInput
          source="type"
          reference="rule_action_type"
          sort={{ field: "description", order: "ASC" }}
        >
          <AutocompleteInput
            optionText="description"
            disabled={true}
            validate={required()}
            filterToQuery={(searchText) => ({ "description@_ilike": searchText })}
          />
        </ReferenceInput>
        <RuleActionValues mode="edit" />
      </SimpleForm>
    </Edit>
  );
};

export default RuleActionEdit;
