import {
  DateInput,
  FormDataConsumer,
  required,
  SimpleForm,
  SimpleFormIterator,
  TextInput,
  useRecordContext, useReference
} from "react-admin";
import QueryKindInput from "../query/QueryKindInput";
import {ReferenceManyInput} from "@react-admin/ra-relationships";
import DiagnosisInput from "../claim/DiagnosisInput";
import CustomDiagnosisDescriptionInput from "./CustomDiagnosisDescriptionInput";
import CustomConditionDescriptionInput from "./CustomConditionDescriptionInput";
import * as React from "react";
import {canEdit} from "../reconciliationreport/domain";
import {Typography} from "@mui/material";
import ProviderInput from "../provider/ProviderInput";

const validateQueryDiagnosis = (value, allValues) => {
  if (allValues['query_kind'] === 'diagnosis' || allValues['query_kind'] === 'yesno') {
    if(!value) {
      return 'Diagnosis is required';
    }
    if (value.length < 1) {
      return 'at least 1 diagnosis is required';
    }
  }
  if (allValues['query_kind'] === 'yesno') {
    if (value.length > 1){
      return 'Only 1 diagnosis is allowed for yes/no queries';
    }
  }
  if (allValues['query_kind'] === 'smart') {
    const countDiagnosis = value.length;
    const countConditions = allValues["@@ra-many/reconciliation_report_query/reconciliation_report_query_condition/reconciliation_report_query_id"][0]["reconciliation_report_query_condition"].length;
    if ((countDiagnosis >= 1) && (countDiagnosis + countConditions > 1)) {
      return 'Only 1 diagnosis or condition is allowed for smart queries';
    }
  }
  return undefined;
};

const validateQueryCondition = (value, allValues) => {
  if (allValues['query_kind'] === 'smart') {
    const countConditions = value.length;
    const countDiagnosis = allValues["@@ra-many/reconciliation_report_query/reconciliation_report_query_diagnosis/reconciliation_report_query_id"][0]["reconciliation_report_query_diagnosis"].length;
    if ((countConditions >= 1) && (countConditions + countDiagnosis > 1)) {
      return 'Only 1 diagnosis or condition is allowed for smart queries';
    }
  }
  return undefined;
};

const validateClinicalIndicators = (value, allValues) => {
  if (allValues['query_kind'] === 'diagnosis' || allValues['query_kind'] === 'smart') {
    if(!value) {
      return 'Clinical indicators are required';
    }
    if (value.length < 1) {
      return 'at least 1 clinical indicator is required';
    }
  }
  return undefined;
}


export const ReconciliationReportQueryForm = (props) => {
  // Diagnosis ID resource. this should be the one you watch
  // @@ra-many/reconciliation_report_query/reconciliation_report_query_diagnosis/reconciliation_report_query_id.0.reconciliation_report_query_diagnosis.0.diagnosis_id
  // Diagnosis description resource. this should be the one you set
  // @@ra-many/reconciliation_report_query/reconciliation_report_query_diagnosis/reconciliation_report_query_id.0.reconciliation_report_query_diagnosis.0.diagnosis_description
  const record = useRecordContext();
  const reportId = record?.report_id;
  const {referenceRecord: report} = useReference({reference: 'reconciliation_report', id: reportId});
  const isDisabled = !canEdit(report?.state);
  const toolbar = props.toolbar;
  if (!record) return <Typography>This function can only be accessed from a report</Typography>;

  return (
    <SimpleForm toolbar={toolbar}>
      <QueryKindInput
        label="Query type"
        source='query_kind'
        validate={required()}
        resettable={false}
        disabled={isDisabled}
      />

      <FormDataConsumer>
        {({ formData }) => (formData.query_kind === 'cdi' || formData.query_kind === 'yesno') &&
          <>
            <DateInput source='latest_service_date' validate={required()} disabled={isDisabled}/>
            <ProviderInput source='latest_service_provider_id' sx={{minWidth: 250}} disabled={isDisabled}/>
          </>
        }
      </FormDataConsumer>

      <FormDataConsumer>
        {({ formData }) => formData.query_kind === 'cdi' &&
          <>
            <TextInput
              label="Clinical documentation improvement query text"
              source='query_text'
              validate={required()}
              fullWidth
              multiline
              resettable={true}
              disabled={isDisabled}
            />
          </>
        }
      </FormDataConsumer>

      <FormDataConsumer>
        {({ formData }) => formData.query_kind !== 'cdi' &&
          <ReferenceManyInput
            label="Diagnoses for query"
            reference="reconciliation_report_query_diagnosis"
            target="reconciliation_report_query_id"
            validate={validateQueryDiagnosis}
            disabled={isDisabled}
          >
            <SimpleFormIterator inline>
              <DiagnosisInput source="diagnosis_id" reference='diagnosis' validate={required()} disabled={isDisabled}/>
              <CustomDiagnosisDescriptionInput
                source="diagnosis_description"
                label="Custom diagnosis description"
                sx={{minWidth: 400}}
                disabled={isDisabled}
              />
            </SimpleFormIterator>
          </ReferenceManyInput>
        }
      </FormDataConsumer>

      <FormDataConsumer>
        {({ formData }) => formData.query_kind === 'smart' &&
          <ReferenceManyInput
            label="Conditions for query"
            reference="reconciliation_report_query_condition"
            target="reconciliation_report_query_id"
            validate={validateQueryCondition}
            disabled={isDisabled}
          >
            <SimpleFormIterator inline>
              <DiagnosisInput source="condition_id" reference='condition' validate={required()} disabled={isDisabled}/>
              <CustomConditionDescriptionInput
                source="condition_description"
                label="Custom condition description"
                sx={{minWidth: 400}}
                disabled={isDisabled}
              />
            </SimpleFormIterator>
          </ReferenceManyInput>
        }
      </FormDataConsumer>

      {/*Fields that only appear for clinical indicator queries: */}
      <FormDataConsumer>
        {({ formData}) => formData.query_kind === 'diagnosis' &&
          <ReferenceManyInput
            label="Clinical indicators for query"
            reference="reconciliation_report_query_clinical_indicator"
            target="reconciliation_report_query_id"
            validate={validateClinicalIndicators}
            disabled={isDisabled}
          >
            <SimpleFormIterator inline>
              <DateInput
                label='Date'
                source='date_reported'
                validate={[required()]}
                disabled={isDisabled}
              />
              <ProviderInput
                source='provider_id'
                sx={{minWidth: 250}}
              />
              <TextInput
                label='Clinical Indicator'
                source='clinical_indicator'
                validate={[required()]}
                sx={{minWidth:400}}
                disabled={isDisabled}
                multiline
              />
              <TextInput
                label='Value'
                source='value'
                sx={{minWidth: 400}}
                disabled={isDisabled}
                multiline
              />
            </SimpleFormIterator>
          </ReferenceManyInput>
        }
      </FormDataConsumer>

      {/*Fields that only appear for smart queries: */}
      <FormDataConsumer>
        {({ formData}) => formData.query_kind === 'smart' &&
          <ReferenceManyInput
            label="Clinical indicators for query"
            reference="reconciliation_report_query_clinical_indicator"
            target="reconciliation_report_query_id"
            validate={validateClinicalIndicators}
            disabled={isDisabled}
          >
            <SimpleFormIterator inline>
              <TextInput
                label='Clinical Indicator'
                source='clinical_indicator'
                validate={[required()]}
                sx={{minWidth:300}}
                disabled={isDisabled}
                multiline
              />
              <TextInput
                label='Operator'
                source='reference_op'
                sx={{minWidth:300}}
                disabled={isDisabled}
                multiline
              />
              <TextInput
                label='Value'
                source='value'
                sx={{minWidth:300}}
                disabled={isDisabled}
                multiline
              />
              <TextInput
                label='Date'
                source='reference_date'
                sx={{minWidth:300}}
                disabled={isDisabled}
                multiline
              />
            </SimpleFormIterator>
          </ReferenceManyInput>
        }
      </FormDataConsumer>
    </SimpleForm>
  )
};

export default ReconciliationReportQueryForm;
