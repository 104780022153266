export const REC_OPEN_DIAGNOSES = 'rec_open_diagnoses_2';
export const RECONCILIATION_REPORT_QUERY = 'reconciliation_report_query';
export const RECONCILIATION_REPORT_QUERY_DIAGNOSIS = 'reconciliation_report_query_diagnosis';
export const RECONCILIATION_REPORT_QUERY_CONDITION = 'reconciliation_report_query_condition';
export const RECONCILIATION_REPORT_QUERY_CLINICAL_INDICATOR = 'reconciliation_report_query_clinical_indicator';

export const humanaRegex = /^H\d{10}$/;
export const mcareRegex =/^\d[A-Z][0-9A-Z]\d[A-Z][0-9A-Z]\d[A-Z]{2}\d{2}$/
export const simplyRegex =/^\d{3}[A-Z]\d{5}$/
export const careplusRegex =/^\d{9}$/
export const devotedRegex = /^D[0-9A-Z]{5}$/;
export const cignaRegex = /^\d{8}$/;
export const aetnaRegex = /^\d{12}$/;
export const unitedRegex = /^\d{9}$/;
export const bcbsRegex = /^H\d{8}$/;
export const medicaRegex =/^\d{9}$/;
export const preferredCareRegex =/^[\dS]{9}$/;
export const avmedRegex = /^A\d{10}$/;
